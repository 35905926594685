<button [ngStyle]="{'color':'white'}" mat-button color="warn" (click)="onClick()">
  <mat-icon >file_upload</mat-icon>
  Choose Image
</button>
<input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}" style="display:none;" />
<div style="min-width: 100%; margin-left: auto;margin-right: auto; ">
    <button [ngStyle]="{'color':'white'}" mat-button color="warn" (click)="cancel()">
  <mat-icon >cancel</mat-icon>
  Close
</button>
    <button [ngStyle]="{'color':'white'}" mat-button color="warn" (click)="save()">
  <mat-icon >save</mat-icon>
  Save
</button>
    <img [src]="croppedImage" width="200px" [style.border]="croppedImage ? '1px solid black' : 'none'" />
    <ul>
        <li *ngFor="let file of files" [@fadeInOut]="file.state">
            <mat-progress-bar [value]="file.progress"></mat-progress-bar>
            <span id="file-label">
  {{file.data.name}} 
  <a title="Retry" (click)="retryFile(file)" *ngIf="file.canRetry">
  <mat-icon>refresh</mat-icon></a>
  <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel">
  <mat-icon>cancel</mat-icon></a>
  </span>
        </li>
    </ul>
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 3" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
</div>