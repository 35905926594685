// globals.ts
import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
 // server: string = 'http://aeonapi.transportrt.com';
server: string = 'http://api.talentboxx.com';
  //server: string = 'https://api.talentboxx.com';
//server: string = 'http://localhost:54481';

}
