import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../shared/global';
import { SearchTermService } from '../brodcast/searchterm';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  id: any; tok: any;shworesend=false;
  constructor(public service: HttpService, private Globals: Globals,
    public snackBar: MatSnackBar, private router: Router,
    private route: ActivatedRoute, private searchTermService: SearchTermService, ) {
    this.route.queryParams.subscribe((params) => {
      console.log("params", this.route.snapshot.paramMap);
      this.id = this.route.snapshot.paramMap.get('id');
      this.tok = this.route.snapshot.paramMap.get('id');
      if (this.id && this.tok) {
        this.service.get("/api/account/verify?uid" + this.id + "&tok=" + this.tok).subscribe((data) => {
          if (data.status == 0) {
            this.showNotification("Account verified succesfully, please login ");
          }
          else {
            this.shworesend=true;
          }
        })
      }
    });
  }

resend(){
  this.error=false;
  this.isloading  =true;
  var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  if(1==1)//(regexp.test(this.user.username))
  {
  this.service.get("/api/account/resend?email=" + encodeURIComponent(this.user.username) ).subscribe((data) => {
    this.isloading  =false;
      this.showNotification(data.message);
  })
}else{
  this.showNotification("Please enter valid email address");
  this.isloading  =false;
}
}

forgotpassword(){
  this.error=false;
  this.isloading  =true;
  var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  if(regexp.test(this.user.username))
  {
  this.service.get("/api/account/forgotpassword?email=" + encodeURIComponent(this.user.username) ).subscribe((data) => {
    this.isloading  =false;
      this.showNotification(data.message);
  })
}else{
  this.showNotification("Please enter valid email address");
  this.isloading  =false;
}

}

  public user: any = {};
  submitted: any;
  ngOnInit() {

  }
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'todos-notification-overlay'
    });
  }
  isloading: any;
  error=false;
  postUser(registerForm) {
    this.error=false;
    this.shworesend=false;
    console.log("registerForm", registerForm);
    this.submitted = true;
    if (registerForm.valid) {
      this.isloading = true;
      // this.user.confirmpassword=this.user.password;
      this.user.grant_type = "password";

      this.service.postUrlEncode("/token", `username=${encodeURIComponent(this.user.username)}&password=${this.user.password}&grant_type=password`).subscribe(
        (data) => {
          console.log("login data", data);
          console.log("login data", data.error);
          localStorage.setItem("authId", data.access_token);
          localStorage.setItem("isAuthenticated", "true");
          if (data.userimage == 1) {
            localStorage.setItem("userImagePath", this.Globals.server + "/uploads/" + data.uimg);
            this.searchTermService.sendMessage(this.Globals.server + "/uploads/" + data.uimg);
          }
          if (!data.error) {
            this.isloading = false;
            // this.showNotification(data.message);
            this.router.navigate(['/']);
            this.searchTermService.sendMessage(undefined)
          }
          else {
           
            this.isloading = false;
            localStorage.removeItem("authId");
            localStorage.removeItem("isAuthenticated");
            localStorage.removeItem("userImagePath");
            this.showNotification(data.error_description);
            this.searchTermService.sendMessage(undefined)
          }
          console.log(data);
        },
        (d) => {
          var error=d.error;
          if(error.error=="invalid_email")
          {
            this.showNotification("Please acivate your account , check your email (including spam) for same");
            this.shworesend=true;
          }
          else
          {
            this.showNotification("something went wrong :  " + error.error_description);
          }
          console.log("error=========>",error);
          this.isloading = false;
          localStorage.removeItem("authId");
          localStorage.removeItem("isAuthenticated");
          localStorage.removeItem("userImagePath");
  
          if(error.error.error_message="invalid_email")
          {this.error=true}
        }
      )
    }
  }

  submit() {
    // alert("called");
    this.submitted = true;
  }

}
