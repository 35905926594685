import { Injectable } from '@angular/core';
import { RequestOptions, Request, RequestMethod, Headers} from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, window } from 'rxjs/operators';
import { Globals } from './global';
import {  Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient, private Globals: Globals, public router: Router) { }

  BASE_URL = this.Globals.server;

  private JHEADER = new HttpHeaders().append( 'Content-Type', 'application/json' );
  private XHEADER = new HttpHeaders().append( 'Content-Type','application/X-www-form-urlencoded');
  private FileHeader= new HttpHeaders().append( 'Content-Type', 'multipart/form-data' ); 
  //private AUTHHEADER = new Headers({ "Authorization": "Bearer " + localStorage.getItem("auth_key") });

  get(param:any): Observable<any> {
    return this.httpClient
      .get(this.BASE_URL + param)
      .pipe(
        map((data: any) => (data))
      );
  }

  getWithAuth(param:any): Observable<any> {
    let AUTHHEADER = new HttpHeaders().append('Authorization', `Bearer ${localStorage.getItem("authId")}`);
    return this.httpClient
      .get(this.BASE_URL + param, { headers: AUTHHEADER } )
      .pipe(
        map((data: any) => (data))
      );
  }

  post(api:any,param: any): Observable<any> {
    return this.httpClient
      .post(this.BASE_URL + api,JSON.stringify( param) , {headers:this.JHEADER})
      .pipe(
        map((data: any) => (data))
      );
  }
  private headers = new Headers({ 'Content-Type': 'application/X-www-form-urlencoded' });
  postUrlEncode(api: any, param: any): Observable<any> {
    return this.httpClient
      .post(this.BASE_URL + api, param, {headers:this.XHEADER})
      .pipe(
      map((data: any) => (data)),
        catchError(error=>this.handleError(error))
      );
  }

  postFile(api: any, param: FormData): Observable<any> {
    return this.httpClient
      .post(this.BASE_URL + api, param, {headers:this.FileHeader})
      .pipe(
      map((data: any) => (data)),
        catchError(error=>this.handleError(error))
      );
  }

  uploadFile(url: string, file: File,request:any): Observable<any> {

    let formData = new FormData();
    formData.append('upload', file);

    let params = new HttpParams();

    const options = {
      params: request,
      reportProgress: true,
      headers: new HttpHeaders().append('Authorization', `Bearer ${localStorage.getItem("authId")}`)//.append('Content-Type', 'multipart/form-data')
    };

    return this.httpClient
    .post(this.BASE_URL + url, formData,options)
    .pipe(
    map((data: any) => (data)),
      catchError(error=>this.handleError(error))
    );
  }


  postWithAuth(api: any, param: any): Observable<any> {
    let body = JSON.stringify(param);
    let XHEADER = new HttpHeaders().append('Authorization', `Bearer ${localStorage.getItem("authId")}`).append('Content-Type', 'application/json');
    return this.httpClient
      .post(this.BASE_URL + api, body, { headers: XHEADER })
      .pipe(
        map((data: any) => (data))
      );
  }

  //*******************End Project Tasks Services */
  private handleError(error: any): Promise<any> {
    if (error.status === 401) {
    // window. = "http://google.com";
      this.router.navigateByUrl(`/login`);
      
      return Promise.reject({ status: "401", message: "unauthorized" });
    }
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error || error.json());
  }
}
