<div style="width: 90%; padding: 10px;">
    <h3>
        Terms of Service
    </h3>
    <p>
        Welcome to TalentBoxx – a platform to showcase your talent to the world.
    </p>
    <p>
        Introduction
        <br/>
        <br/>
    </p>
    <p>
        Thank you for using the TalentBoxx products, services and features provided to you from the platform (collectively, the “Service”).
    </p>
    <p>
        Our Service
    </p>
    <p>
        The Service allows you to discover, watch and share videos and other content, provides a forum for people to connect, inform, and inspire others across the globe, and acts as a distribution platform for original content creators and advertisers large
        and small. We provide lots of information about our products and how to use them in About us Section.
    </p>
    <p>
        Your Service Provider
    </p>
    <p>
        The entity providing the Service is TalentBoxx, a proprietory concern located in Mumbai (referred to as “TalentBoxx”, “we”, “us”, or “our”). References to TalentBoxx’s “Affiliates” in these terms means the other companies within the group (now or in the
        future).
    </p>
    <p>
        Applicable Terms
    </p>
    <p>
        <br/> Your use of the Service is subject to these terms. Any other links or references provided in these terms are for informational use only and are not part of the Agreement. Please read this Agreement carefully and make sure you understand
        it. If you do not understand the Agreement, or do not accept any part of it, then you may not use the Service.
    </p>
    <p>
        By accepting this Agreement, you affirm that you are 18 years of age or above and are fully competent to enter into this Agreement, and to abide by and comply with this Agreement. In case you are below 18 years of age, the restrictions and requirements
        below apply.
    </p>
    <p>
        Who may use the Service?
    </p>
    <p>
        Age Requirements You must be at least 13 years old to use the Service.
    </p>
    <p>
        Permission by Parent or Guardian
    </p>
    <p>
        If you are considered a minor in your country, you represent that you have your parent or guardian’s permission to use the Service. Please have them read this Agreement with you.
    </p>
    <p>
        If you are a parent or legal guardian of a minor in your country, by allowing your child to use the Service, you are subject to the terms of this Agreement and responsible for your child’s activity on the Service.
    </p>
    <p>
        Businesses
        <br/>
        <br/>
    </p>
    <p>
        If you are using the Service on behalf of a company or organisation, you represent that you have authority to act on behalf of that entity, and that such entity accepts this Agreement.
    </p>
    <p>
        Your Use of the Service
    </p>
    <p>
        Content on the Service
    </p>
    <p>
        The content on the Service includes videos, audio (for example music and other sounds), graphics, photos, text (such as comments and scripts), branding (including trade names, trademarks, service marks, or logos), interactive features, software, metrics,
        and other materials (collectively, "Content”).
    </p>
    <p>
        Content is the responsibility of the person or entity that provides it to the Service. TalentBoxx is under no obligation to host or serve Content. If you see any Content you believe does not comply with this Agreement, or the law, you can report it to
        us.
    </p>
    <p>
        Your TalentBoxx Account
    </p>
    <p>
        You can use parts of the Service, such as browsing and searching for Content, without having a TalentBoxx account. However, you do need a TalentBoxx account to use some features. With a TalentBoxx account, you may be able to like videos, participate in
        challenges and post videos.
    </p>
    <p>
        To protect your TalentBoxx account, keep your password confidential. You should not reuse your TalentBoxx account password on third-party applications.
    </p>
    <p>
        Your Information
    </p>
    <p>
        Our <a href="http://app.talentboxx.com/privacy">Privacy Policy</a> explains how we treat your personal data and protect your privacy when you use the Service..
    </p>
    <p>
        We will process any audio or audio visual content uploaded by you to the Service if they are in accordance with the<a>TalentBoxx </a> <a>Terms</a>,
    </p>
    <p>
        .
    </p>
    <p>
        Permissions and Restrictions
    </p>
    <p>
        You may use the Service as made available to you, as long as you comply with this Agreement and applicable law. You may view or listen to Content for your personal, non-commercial use. You may also show TalentBoxx videos through the embeddable TalentBoxx
        player.
    </p>
    <p>
        The following restrictions apply to your use of the Service. You are not allowed to:
    </p>
    <p>
        1. access, reproduce, download, distribute, transmit, broadcast, display, sell, license, alter, modify or otherwise use any part of the Service or any Content except: (a) as expressly authorized by the Service; or (b) with prior written permission from
        TalentBoxx and, if applicable, the respective rights holders;
    </p>
    <p>
        2. circumvent, disable, fraudulently engage with, or otherwise interfere with any part of the Service (or attempt to do any of these things), including security-related features or features that (a) prevent or restrict the copying or other use of Content
        or (b) limit the use of the Service or Content;
    </p>
    <p>
        3. access the Service using any automated means (such as robots, botnets or scrapers) except (a) in the case of public search engines, in accordance with TalentBoxx’s robots.txt file; or (b) with TalentBoxx’s prior written permission;
    </p>
    <p>
        4. collect or harvest any information that might identify a person (for example, usernames), unless permitted by that person or allowed under section (3) above;
    </p>
    <p>
        5. use the Service to distribute unsolicited promotional or commercial content or other unwanted or mass solicitations;
    </p>
    <p>
        6. cause or encourage any inaccurate measurements of genuine user engagement with the Service, including by paying people or providing them with incentives to increase a video’s views, likes, or dislikes, or to increase a channel’s subscribers, or otherwise
        manipulate metrics in any manner;
    </p>
    <p>
        7. misuse any reporting, flagging, complaint, dispute, or appeals process, including by making groundless, vexatious, or frivolous submissions;
    </p>
    <p>
        8. use the Service to view or listen to Content other than for personal, non-commercial use (for example, you may not publicly screen videos or stream music from the Service); or
    </p>
    <p>
        9. use the Service to (a) sell any advertising, sponsorships, or promotions placed on, around, or within the Service or Content, or (b) sell advertising, sponsorships, or promotions on any page of any website or application that only contains Content
        from the Service or where Content from the Service is the primary basis for such sales (for example, selling ads on a webpage where TalentBoxx videos are the main draw for users visiting the webpage).
    </p>
    <p>
        Reservation
    </p>
    <p>
        Using the Service does not give you ownership of or rights to any aspect of the Service, including user names or any other Content posted by others or TalentBoxx.
    </p>
    <p>
        Changes to the Service
    </p>
    <p>
        TalentBoxx is constantly changing and improving the Service. We may also need to alter or discontinue the Service, or any part of it, in order to make performance or security improvements, change functionality and features, make changes to comply with
        law, or prevent illegal activities on or abuse of our systems. These changes may affect all users, some users or even an individual user. Whenever reasonably possible, we will provide notice when we discontinue or make material changes to our
        Service that will have an adverse impact on the use of our Service. However, you understand and agree that there will be times when we make such changes without notice, such as where we feel we need to take action to improve the security and operability
        of our Service, prevent abuse, or comply with legal requirements.
    </p>
    <p>
        Your Content and Conduct
    </p>
    <p>
        Uploading Content
    </p>
    <p>
        If you have a TalentBoxx account, you may be able to upload Content to the Service. You may use your Content to promote your business or artistic enterprise. If you choose to upload Content, you must not submit to the Service any Content that does not
        comply with this Agreement or the law. For example, the Content you submit must not include third-party intellectual property (such as copyrighted material) unless you have permission from that party or are otherwise legally entitled to do so.
        You are legally responsible for the Content you submit to the Service. We may use automated systems that analyze your Content to help detect infringement and abuse, including spam and malware.
    </p>
    <p>
        Rights you Grant
    </p>
    <p>
        You retain ownership rights in your Content. However, we do require you to grant certain rights to TalentBoxx and other users of the Service, as described below.
    </p>
    <p>
        License to TalentBoxx
    </p>
    <p>
        By providing Content to the Service, you grant to TalentBoxx a worldwide, non-exclusive, royalty-free, transferable, sublicensable license to use that Content (including to reproduce, distribute, prepare derivative works, display and perform it). TalentBoxx
        may only use that Content in connection with the Service and TalentBoxx’s (and its successors’ and Affiliates) business, including for the purpose of promoting and redistributing part or all of the Service.
    </p>
    <p>
        License to Other Users
    </p>
    <p>
        You also grant each other user of the Service a worldwide, non-exclusive, royalty-free license to access your Content through the Service, and to use that Content, including to reproduce, distribute, prepare derivative works, display and perform it, only
        as enabled by a feature of the Service (such as video playback or embeds). For clarity, this license does not grant any rights or permissions for a user to make use of your Content independent of the Service.
    </p>
    <p>
        Duration of License
    </p>
    <p>
        The licenses granted by you continue for a commercially reasonable period of time after you remove or delete your Content from the Service. You understand and agree, however, that TalentBoxx may retain, but not display, distribute, or perform, server
        copies of your videos that have been removed or deleted.
    </p>
    <p>
        Removing Your Content
    </p>
    <p>
        You may
        <a>
        remove your Content
    </a> from the Service at any time. You also have the option to
        <a>
        make a copy of your Content
    </a> before removing it. You must remove your Content if you no longer have the rights required by these terms.
    </p>
    <p>
        Removal of Content By TalentBoxx
    </p>
    <p>
        If we reasonably believe that any Content is in breach of this Agreement or may cause harm to TalentBoxx, our users, or third parties, we may remove or take down that Content in our discretion. We will notify you with the reason for our action unless
        we reasonably believe that to do so: (a) would breach the law or the direction of a legal enforcement authority or would otherwise risk legal liability for TalentBoxx or our Affiliates; (b) would compromise an investigation or the integrity or
        operation of the Service; or (c) would cause harm to any user, other third party, TalentBoxx or our Affiliates.
    </p>
    <p>
        Copyright Protection
    </p>
    <p>
        Account Suspension &amp; Termination
    </p>
    <p>
        Terminations by You
    </p>
    <p>
        You may stop using the Service at any time by deleting your TalentBoxx Account, which involves closing your TalentBoxx account and removing your data.
    </p>
    <p>
        Terminations and Suspensions by TalentBoxx for Cause
    </p>
    <p>
        TalentBoxx may suspend or terminate your use of the Services, your TalentBoxx account, or your TalentBoxx account’s access to all or part of the Service if (a) you materially or repeatedly breach this Agreement; (b) we are required to do so to comply
        with a legal requirement or a court order; or (c) we believe there has been conduct that creates (or could create) liability or harm to any user, other third party, TalentBoxx or our Affiliates.
    </p>
    <p>
        Terminations by TalentBoxx for Service Changes
    </p>
    <p>
        TalentBoxx may terminate your use of the Services, or your TalentBoxx account’s access to all or part of the Service if TalentBoxx believes, in its sole discretion, that provision of the Service to you is no longer commercially viable.
    </p>
    <p>
        Notice for Termination or Suspension
    </p>
    <p>
        We will notify you with the reason for termination or suspension by TalentBoxx unless we reasonably believe that to do so: (a) would violate the law or the direction of a legal enforcement authority, or would otherwise risk legal liability for TalentBoxx
        or our Affiliates; (b) would compromise an investigation or the integrity or operation of the Service; or (c) would cause harm to any user, other third party, TalentBoxx or our Affiliates. Where TalentBoxx is terminating your use for Service changes,
        where reasonably possible, you will be provided with sufficient time to export your Content from the Service.
    </p>
    <p>
        Effect of Account Suspension or Termination
    </p>
    <p>
        If your TalentBoxx account is terminated or your TalentBoxx account’s access to the Service is restricted, you may continue using certain aspects of the Service (such as viewing only) without an account, and this Agreement will continue to apply to such
        use. If you believe your TalentBoxx account has been terminated in error, you can communicate the reasons to us using the contact us link.
    </p>
    <p>
        Other Legal Terms
    </p>
    <p>
        Warranty Disclaimer
    </p>
    <p>
        OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY LAW, THE SERVICE IS PROVIDED “AS IS” AND TALENTBOXX DOES NOT MAKE ANY SPECIFIC COMMITMENTS OR WARRANTIES ABOUT THE SERVICE. FOR EXAMPLE, WE DON’T MAKE ANY WARRANTIES ABOUT: (A) THE CONTENT
        PROVIDED THROUGH THE SERVICE; (B) THE SPECIFIC FEATURES OF THE SERVICE, OR ITS ACCURACY, RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS; OR (C) THAT ANY CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE SERVICE.
    </p>
    <p>
        Limitation of Liability
    </p>
    <p>
        EXCEPT AS REQUIRED BY APPLICABLE LAW, TALENTBOXX, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS WILL NOT BE RESPONSIBLE FOR ANY LOSS OF PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL, OR ANTICIPATED SAVINGS; LOSS OR CORRUPTION OF DATA;
        INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES CAUSED BY:
    </p>
    <p>
        1. ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE;
    </p>
    <p>
        2. PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE SERVICE;
    </p>
    <p>
        3. ANY UNAUTHORIZED USE OF THE SERVICE;
    </p>
    <p>
        4. ANY INTERRUPTION OR CESSATION OF THE SERVICE;
    </p>
    <p>
        5. ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY;
    </p>
    <p>
        6. ANY CONTENT WHETHER SUBMITTED BY A USER OR TALENTBOXX, INCLUDING YOUR USE OF CONTENT; AND/OR
    </p>
    <p>
        7. THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT.
    </p>
    <p>
        THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM ASSERTED IS BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY.
    </p>
    <p>
        TO THE EXTENT PERMITTED BY APPLICABLE LAW, TALENTBOXX AND ITS AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICE IS LIMITED TO THE AMOUNT OF REVENUE THAT TALENTBOXX HAS PAID TO YOU FROM YOUR USE OF THE SERVICE IN THE 12
        MONTHS BEFORE THE DATE OF YOUR NOTICE, IN WRITING TO TALENTBOXX, OF THE CLAIM.
    </p>
    <p>
        Indemnity
    </p>
    <p>
        To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless TalentBoxx, its Affiliates, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or
        debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of the Service; (ii) your violation of any term of these Terms of Service; (iii) your violation of any third party right, including without limitation
        any copyright, property, or privacy right; or (iv) any claim that your Content caused damage to a third party. This defense and indemnification obligation will survive this Agreement and your use of the Service.
    </p>
    <p>
        Third-Party Links
    </p>
    <p>
        The Service may contain links to third-party websites and online services that are not owned or controlled by TalentBoxx. TalentBoxx has no control over, and assumes no responsibility for, such websites and online services. Be aware when you leave the
        Service; we suggest you read the terms and privacy policy of each third-party website and online service that you visit.
    </p>
    <p>
        About this Agreement
    </p>
    <p>
        Modifying this Agreement
    </p>
    <p>
        We may modify this Agreement, for example, to reflect changes to our Service or for legal, regulatory, or security reasons. TalentBoxx will provide reasonable advance notice of any material modifications to this Agreement and the opportunity to review
        them except that, where legally permitted, modifications addressing newly available features of the Service or modifications made for legal reasons may be effective immediately without notice. Modifications to this Agreement will only apply going
        forward. If you do not agree to the modified terms, you should remove any Content you have uploaded and discontinue your use of the Service.
    </p>
    <p>
        Continuation of this Agreement
    </p>
    <p>
        If your use of the Service ends, the following terms of this Agreement will continue to apply to you: “Other Legal Terms”, “About This Agreement”, and the licenses granted by you will continue as described under “Duration of License”.
    </p>
    <p>
        Severance
        <br/>
        <br/>
    </p>
    <p>
        If it turns out that a particular term of this Agreement is not enforceable for any reason, this will not affect any other terms.
    </p>
    <p>
        No Waiver
    </p>
    <p>
        If you fail to comply with this Agreement and we do not take immediate action, this does not mean that we are giving up any rights that we may have (such as the right to take action in the future).
    </p>
    <p>
        Interpretation
    </p>
    <p>
        In these terms, “include” or “including” means “including but not limited to,” and any examples we give are for illustrative purposes.
    </p>
    <p>
        Governing Law
    </p>
    <p>
        This agreement shall be subject to Mumbai Jurisdiction which will govern all disputes arising out of or relating to these terms or the Service, regardless of conflict of laws rules.
    </p>
    <p>
        Effective as of June 07, 2020
    </p>
</div>