<app-searchnav type="full">
</app-searchnav>
<div class="container-fluid text-center" *ngIf="!vprof && userdetails">
    <div class="row content">
        <div class="col-sm-12 text-center">
            <div class="propdivSummary">
                <mat-card class="example-card">
                    <mat-card-header style="padding:10px;">
                        <div mat-card-avatar class="example-header-image"
                            [ngStyle]="{'background-image': 'url('+userImagePath+')'}"></div>
                        <mat-card-title style="color: #4a148c;">{{userdetails.name}} <span
                                style="font-size: 12px; font-weight:200;color:#8e24aa">@{{userdetails.uid}}</span>
                        </mat-card-title>
                        <mat-card-subtitle style="margin-top: 10px;">
                            <button mat-button color="primary" (click)="vprof=true;">
                                View Profile
                            </button>
                        </mat-card-subtitle>
                    </mat-card-header>
                </mat-card>

            </div>
        </div>
    </div>
</div>
<div class="mainbg" *ngIf="vprof && userdetails">
    <div class="container-fluid text-center">
        <div class="row content" *ngIf="userdetails">
            <div class="col-sm-12 text-center">
                <div class="propdiv mainbgProf">

                    <img [src]="userImagePath" (click)="changeDp()" alt="user profile picture" class="circle-img">
                    <div class="center-text nopad">
                        <div class="text5">
                            <span *ngIf="!editer">{{userdetails.name}}</span>
                            <button *ngIf="!editer" mat-icon-button color="warn" (click)="getPAssword();">
                                <mat-icon [ngStyle]="{'color':'white'}">edit</mat-icon>
                            </button> |
                            <button mat-button color="warn" (click)="vprof=false;"> Back</button>
                            <br />

                        </div>
                        <div class="example-button-row" matRipple>

                            <button *ngIf="editer" mat-button color="warn" (click)="openBottomSheet(0);">
                                Delete Account
                            </button>
                            <button *ngIf="editer" mat-button color="warn" (click)="openBottomSheet(1);">
                                Remove Picture
                            </button>
                            <button *ngIf="editer" mat-button color="warn" (click)="updateUser();">
                                Save Changes
                            </button>
                            <button *ngIf="editer" mat-button color="warn" (click)="editer=false;">
                                Cancel Changes
                            </button>
                        </div>
                        <div class="text5 example-button-row" *ngIf="editer">
                            <div style="min-width: 400px; margin-left: auto; margin-right: auto; padding: 5px;">
                                <mat-form-field style="width:90%" class="example-full-width">
                                    <mat-label>Name</mat-label>
                                    <input matInput [ngClass]="{error: name.errors && submitted}" required name="name"
                                        [(ngModel)]="userdetails.name" #name="ngModel">
                                </mat-form-field>

                                <mat-form-field style="width:90%">
                                    <mat-label>Date of Birth</mat-label>
                                    <input name="dob" class="form-control" (click)="picker.open()" required
                                        [ngClass]="{error: dob.errors && submitted}" matInput disabled
                                        [(ngModel)]="userdetails.dob" #dob="ngModel" [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                                </mat-form-field>

                                <div style="width:90%">
                                    <label>Gender: </label>
                                    <div class="text5" *ngIf="editer">
                                        <label class="selectionbox">
                                            Female
                                            <input type="radio" [value]="1" [(ngModel)]="userdetails.gender"
                                                #gender="ngModel" checked="userdetails.gender==1" name="gender">
                                            <span class="radiomark"></span>
                                        </label>
                                        <label class="selectionbox">
                                            Male
                                            <input type="radio" [value]="2" [(ngModel)]="userdetails.gender"
                                                checked="userdetails.gender!=1" #gender="ngModel" name="gender">
                                            <span class="radiomark"></span>
                                        </label>
                                    </div>
                                </div>

                                <mat-form-field style="width:90%">
                                    <mat-label>Mobile</mat-label>
                                    <input matInput [ngClass]="{error: mobile.errors && submitted}" required
                                        name="mobile" [(ngModel)]="userdetails.phone" #mobile="ngModel"
                                        class="form-control">
                                </mat-form-field>

                                <mat-form-field style="width:90%">
                                    <mat-label>Password</mat-label>
                                    <input matInput type="password" [ngClass]="{error: password.errors && submitted}"
                                        required name="password" [(ngModel)]="userdetails.password" #password="ngModel"
                                        class="form-control">
                                </mat-form-field>
                                <!-- <input type="text" [ngClass]="{error: name.errors && submitted}" required name="name" [(ngModel)]="userdetails.name" #name="ngModel"  id="pwd" placeholder="name" autocomplete="off"> -->
                            </div>
                        </div>
                    </div>
                    <div class="id-text">@{{userdetails.uid}}</div>


                    <div *ngIf="!editer" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                        class="border-top" fxLayoutGap="10px">
                        <div fxFlex="0 1 calc(20% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
                            <div class="row content">
                                <div class="text4" *ngIf="!editer">Date of Birth</div>
                                <div class="text5" *ngIf="!editer">{{userdetails.dob|date}}</div>
                                <!-- <div class="text5" *ngIf="editer">
                                 
                                </div> -->
                            </div>
                        </div>

                        <div fxFlex="0 1 calc(20% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
                            <div class="row content">
                                <div class="text4">Gender</div>
                                <div class="text5" *ngIf="userdetails.gender==1 && !editer">Female</div>
                                <div class="text5" *ngIf="userdetails.gender==2 && !editer">Male</div>
                                <!-- <div class="text5" *ngIf="editer">
                                    <label class="selectionbox">
                Female
                <input type="radio" [value]="1" [(ngModel)]="userdetails.gender" #gender="ngModel" checked="userdetails.gender==1" name="gender">
                <span class="radiomark"></span>
              </label>
                                    <label class="selectionbox">
                Male
                <input type="radio" [value]="2" [(ngModel)]="userdetails.gender" checked="userdetails.gender!=1" #gender="ngModel" name="gender">
                <span class="radiomark"></span>
              </label>
                                </div> -->
                            </div>
                        </div>

                        <div fxFlex="0 1 calc(20% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
                            <div class="row content">
                                <div class="text4">Mobile</div>
                                <div class="text5" *ngIf="!editer">+91 {{userdetails.phone}}</div>
                                <!-- <div class="form-group" *ngIf="editer">
                                    <input type="number" [ngClass]="{error: mobile.errors && submitted}" required name="mobile" [(ngModel)]="userdetails.phone" #mobile="ngModel" class="form-control" id="pwd" placeholder="Mobile">
                                </div> -->
                            </div>
                        </div>

                        <div fxFlex="0 1 calc(20% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
                            <div class="row content">
                                <div class="text4">Email</div>
                                <div title="{{userdetails.email}}" class="text5">
                                    {{userdetails.email | truncate:['20'] }}</div>
                            </div>
                        </div>

                        <div fxFlex="0 1 calc(20% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
                            <div class="row content">
                                <div class="text4">Password</div>
                                <div class="text5" *ngIf="!editer">*********</div>
                                <!-- <div class="form-group" *ngIf="editer">
                                    <input type="password" [ngClass]="{error: password.errors && submitted}" required name="password" [(ngModel)]="userdetails.password" #password="ngModel" class="form-control" id="pwd" placeholder="Password" autocomplete="off">
                                </div> -->
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
    <img *ngIf="isloading" src="assets/images/talent-box-loading.gif" width="60px" class="text-center">
</div>