import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { ShowcaseComponent } from './showcase/showcase.component';
import { UploadComponent } from './auth/upload/upload.component';
import { SearchShowcaseComponent } from './searchresult/showcase.component';
import { MyuploadsComponent } from './auth/myuploads/myuploads.component';
import { VideoSlateComponent } from './video-slate/video-slate.component';
import { LoaderComponent } from './shared/loader/loader.component'
import { ContactComponent } from './shared/contact/contact.component';
import { TermsComponent } from './shared/terms/terms.component';
import { PrivacyComponent } from './shared/privacy/privacy.component';
import { PastvidComponent } from './pastvid/pastvid.component';
import { VideoLinkComponent } from './video-link/video-link.component';
const routes: Routes = [
  { path: '', component: ShowcaseComponent },
  // dont forget to delete below line
  { path: 'home/:id', component: ShowcaseComponent },
  { path: 't/:id', component: VideoSlateComponent },
  { path: 'ct/:id', component: PastvidComponent },
  { path: 'home', component: ShowcaseComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: 'verify/:id/:tok', component: LoginComponent },
  { path: 'profile', component: MyuploadsComponent },
  { path: 'about', component: HomeComponent },
  { path: 'upload', component: UploadComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'search/:id', component: SearchShowcaseComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'video-link', component: VideoLinkComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
