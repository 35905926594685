<app-nav></app-nav>
<div class="container" style="padding-top:100px; ">

    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top" fxLayoutGap="0px">
        <div fxFlex="29%" fxFlex.lt-md="29%" fxFlex.lt-sm="100%">
            <div style="background-color: crimson;padding-top: 15px; padding-bottom: 20px;color: #fff;">
                <h1>Contact Us</h1>
                <mat-list>
                    <mat-list-item>
                        <mat-icon matListIcon>room</mat-icon>
                        <p matLine>
                            <span> Mumbai <br/>India</span>
                        </p>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListIcon>alternate_email</mat-icon>
                        <p matLine>
                            <span> support@talentboxx.com</span>
                        </p>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListIcon>settings_phone</mat-icon>
                        <p matLine>
                            <span> +91 9768254118 </span>
                        </p>
                    </mat-list-item>

                </mat-list>
            </div>
        </div>
        <div fxFlex="70%" fxFlex.lt-md="70%" fxFlex.lt-sm="100%">
            <div style="padding-top: 15px; padding-bottom: 20px; ">
                <h1>Get in Touch <br/> <small>Feel freee to drop a line to us below</small></h1>

                <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" style="padding-left: 50px;">
                    <p>
                        <mat-form-field class="example-full-width">
                            <mat-label>Name</mat-label>
                            <input matInput [formControl]="uname" [errorStateMatcher]="matcher" placeholder="Ex. John Doe">
                            <mat-error *ngIf="uname.hasError('required')">
                                Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field class="example-full-width">
                            <mat-label>Email</mat-label>
                            <input matInput [formControl]="email" [errorStateMatcher]="matcher" placeholder="Ex. pat@example.com">
                            <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="email.hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field class="example-full-width">
                            <mat-label>Message</mat-label>
                            <textarea matInput [formControl]="message" [errorStateMatcher]="matcher" placeholder="Enter your message here"></textarea>
                            <mat-hint>Feel free to submit any feedback!</mat-hint>

                            <mat-error *ngIf="message.hasError('required')">
                                Message is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <img *ngIf="isloading" src="assets/images/talent-box-loading.gif" width="60px" class="text-center">
                        <button *ngIf="!isloading" mat-raised-button color="warn" type="submit">Submit</button>
                    </p>
                </form>
            </div>
        </div>

    </div>
</div>




<app-footer></app-footer>