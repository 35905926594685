<app-profile *ngIf="userdetails"></app-profile>
<form class="register-form" name="register-form" #registerForm="ngForm" (ngSubmit)="postFile(registerForm)">
    <div style="width:90%; margin-left:auto; margin-right:auto; margin-top:20px">
        <hr />

        <div class="pcardname">Submit Your Talent</div>
        <div class="id-text-pcard">Click to select the challenge for your submission</div>


        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top"
            fxLayoutGap="10px" *ngIf="challenges">
            <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%"
                *ngFor="let item of challenges">
                <mat-card class="example-card" (click)="setchallenge(item)">
                    <mat-card-header>
                        <mat-card-title>{{item.title}}</mat-card-title>
                        <mat-card-subtitle>{{item.uname }}</mat-card-subtitle>
                    </mat-card-header>
                    <div class="bx-overlay" *ngIf="curitem==item">
                        <mat-icon [ngStyle]="{'color':'white'}"
                            style="margin-top:40px; font-size:100px; width:100%; height:150px">done</mat-icon>
                    </div>

                    <!-- <iframe *ngIf="item?.safeexternal?.includes('.m3u8') == false" class="iframe" width="100%"
                        height="150" [src]="item.safeexternal" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe> -->

                    <app-video-link [id]="" [url]="item?.external" sourceType="application/x-mpegURL">
                    </app-video-link>

                    <mat-card-content>
                        <p>
                            {{item.description}}
                            <br /><span style="font-size: 10px;">{{item.date|date}}</span>
                        </p>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

    </div>
    <!--main form-->
    <div class="mainform" *ngIf="curitem!=null">
        <p>
            <input type="text" [ngClass]="{error: dhallengetitle.errors && submitted}" class="form-control" required
                name="dhallengetitle" [(ngModel)]="request.ChallengeTitle" #dhallengetitle placeholder="File Title">
            <mat-error *ngIf="registerForm.controls['dhallengetitle'].invalid && submitted">Enter valid Title
            </mat-error>
            <textarea [ngClass]="{error: description.errors && submitted}" class="form-control" required
                name="description" [(ngModel)]="request.Description" #description placeholder="Description"></textarea>
            <mat-error *ngIf="registerForm.controls['description'].invalid && submitted">Enter valid description
            </mat-error>
        </p>

        <div class="propdivSummaryupload" (click)="onClick()">


            <mat-grid-list cols="1" rowHeight="150px">
                <mat-grid-tile colspan="1" rowspan="1">
                    <div>
                        <button class="danger" mat-button>
                            <mat-icon [ngStyle]="{'color':'gray'}">backup</mat-icon>


                        </button><br />
                        <span class="danger">
                            Select your video
                        </span>
                        <br />
                        <span class="id-text-pcard ">
                            Cllick here to select your video
                        </span>
                        <label for="input-file-id" class="md-button md-raised md-primary">Max Size : 150mb</label>
                        <input type="file" id="fileUpload" name="fileUpload" multiple="false" accept="{{accept}}"
                            (change)="fileChange($event)" name="filep" style="display:none;" />
                        <p *ngFor="let file of files" [@fadeInOut]="file.state">
                            <mat-progress-bar [value]="file.progress"></mat-progress-bar>
                            <span id="file-label">
                                <!--  Processing {{file.data.name}} --->
                                <a title="Retry" *ngIf="file.canRetry">
                                    <mat-icon>refresh</mat-icon>
                                </a>
                                <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel">
                                    <mat-icon>cancel</mat-icon>
                                </a>
                            </span>
                        </p>
                    </div>
                </mat-grid-tile>

            </mat-grid-list>
        </div>


        <p style="text-align:center">
            <img *ngIf="isloading" src="assets/images/talent-box-loading.gif" width="60px" class="text-center">
            <button *ngIf="!isloading" type="submit" class="btn btn-warn warn btn1">Upload</button>

        </p>

    </div>
    <!---->





    <div>






    </div>
</form>
<app-footer (click)="scrooler()"></app-footer>