import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpService } from '../shared/http.service';
import { Globals } from '../shared/global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.css']
})
export class ShowcaseComponent implements OnInit {
  id: any;
  BASE_URL = this.Globals.server;
  constructor(public service: HttpService, private Globals: Globals,
    public snackBar: MatSnackBar, private router: Router,
    private route: ActivatedRoute, private sanitizer: DomSanitizer, public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) { }
  videoId: any;
  queryid: any;
  video: any;

  videodata: any = {};
  openDialog(x): void {
    //b.preventDefault();
    this.setView(x);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '800px',
      maxHeight: '90vh',
      data: x
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }
  setView(id) {

    this.service.getWithAuth("/FileViews?add=1&id=" + id.fileid).subscribe(
      (data) => {
        if (data.status == 0) {
          this.isloading = false;
          id.views = id.views + 1;
          console.log("file", id);
          this.challenges = data.message;
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }

  ngOnInit() {
    this.getChallenges();
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log("params", this.route.snapshot.paramMap);
      this.id = this.route.snapshot.paramMap.get('id');
      this.getChallenge(this.id);
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');

      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    });
  }
  url: any;
  thumburl: any;
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'todos-notification-overlay'
    });
  }
  challenges: any;
  isloading: any;
  baseurl = "http://tbxa.btrx.in/uploads/";
  title: any; desc: any; cat: any; date: any;
  userChallenges: any;
  pastChallenges: any;
  getChallenges() {
    this.isloading = true;
    this.service.getWithAuth("/GetAllChallenges").subscribe(
      (data) => {
        if (data.status == 0) {

          this.userChallenges = data.message;
          if (data.pastchallenges.length > 0)
            this.pastChallenges = data.pastchallenges;
          this.trendChallenges = data.trending;
          var data = data.message[0];

          console.log("this.trendChallenges=======", this.trendChallenges);

          /*this.userChallenges.forEach(element => {element.safeexternal= this.sanitize(element.external);
            element.files.forEach(x => {x.safeexternal= this.sanitize(x.external)
            });
          });*/

          /*this.pastChallenges.forEach(element => {element.safeexternal= this.sanitize(element.external);
            element.files.forEach(x => {x.safeexternal= this.sanitize(x.external)
            });
          });*/

          /*this.trendChallenges.forEach(element => {element.safeexternal= this.sanitize(element.external);
          });*/

          console.log("sanitize", this.url);
          this.isloading = false;

          if (this.id > 0) {
            this.getChallenge(this.id);
          }
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }
  trendChallenges;
  getnew() {
    this.isloading = true;
    this.service.get(`/GetNextTrending?skip=${this.trendChallenges.length}`).subscribe(data => {

      console.log("data ------------", data);
      this.trendChallenges = data;
      /*data.forEach(element => {
        element.safeexternal = this.sanitize(element.external);
        this.trendChallenges.push(element);
      })*/

      this.isloading = false;
      // console.log("x ------------",x);
    });

  }

  getChallenge(id) {
    this.isloading = true;
    this.service.getWithAuth("/GetAllChallenges?id=" + id).subscribe(
      (data) => {
        if (data.status == 0) {

          var data = data.message[0];

          this.thumburl = this.baseurl + data.thumb;
          this.url = this.sanitize(data.external);
          this.title = data.title;
          this.desc = data.description;
          this.cat = data.category;
          this.date = data.date;
          console.log("sanitize", this.url);
          this.isloading = false;
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }
  geturl(x) {
    return "http://api.talentboxx.com//uploads/" + x.thumb;
  }
  GetFile(id) {
    this.service.get("/GetFilleDetails?id=" + id).subscribe(
      (data) => {
        this.video = data.result;

        this.url = `${this.BASE_URL}/images/${this.video.url}`;
        this.thumburl = `${this.BASE_URL}/images/${this.video.thumb}.jpeg`;
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    )
  }
  public show: boolean = true;
  errorHandler(event: any) {
    event.target.src = "../../assets/images/video-placeholder.png";
  }

}
