import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {
      HttpClient, HttpResponse, HttpRequest,
      HttpEventType, HttpErrorResponse, HttpHeaders
} from '@angular/common/http';
import { Subscription, of } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { Globals } from '../global';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
      selector: 'app-file-upload',
      templateUrl: './file-upload.component.html',
      styleUrls: ['./file-upload.component.css'],
      animations: [
            trigger('fadeInOut', [
                  state('in', style({ opacity: 100 })),
                  transition('* => void', [
                        animate(300, style({ opacity: 0 }))
                  ])
            ])
      ]
})
export class FileUploadComponent implements OnInit {
      imageChangedEvent: any = '';
      croppedImage: any = '';
      @Input() text = 'Edit Image';
      /** Name used in form which will be sent in HTTP request. */
      @Input() param = 'file';
      /** Target URL for file uploading. */
      @Input() target = 'https://file.io';
      /** File extension that accepted, same as 'accept' of <input type="file" />. 
          By the default, it's set to 'image/*'. */
      @Input() accept = 'image/*';
      /** Allow you to add handler after its completion. Bubble up response text from remote. */
      @Output() complete = new EventEmitter<string>();

      files: Array<FileUploadModel> = [];

      constructor(private _http: HttpClient, private Globals: Globals) {
      }

      ngOnInit() {
      }

      onClick() {
            const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
            fileUpload.onchange = () => {
                  for (let index = 0; index < fileUpload.files.length; index++) {
                        const file = fileUpload.files[index];
                        this.files.push({
                              data: file, state: 'in',
                              inProgress: false, progress: 0, canRetry: false, canCancel: true
                        });
                  } this.imageChangedEvent = event;
                  //this.uploadFiles();
            };
            fileUpload.click();
      }

      cancelFile(file: FileUploadModel) {
            file.sub.unsubscribe();
            this.removeFileFromArray(file);
      }

      retryFile(file: FileUploadModel) {
            this.uploadFile(file);
            file.canRetry = false;
      }

      private uploadFile(file: FileUploadModel) {
            const fd = new FormData();
            fd.append(this.param, file.data);
            let AUTHHEADER = new HttpHeaders().append('Authorization', `Bearer ${localStorage.getItem("authId")}`);
            const req = new HttpRequest('POST', this.Globals.server + "/" + this.target, fd, {
                  reportProgress: true, headers: AUTHHEADER
            });

            file.inProgress = true;
            file.sub = this._http.request(req).pipe(
                  map(event => {
                        switch (event.type) {
                              case HttpEventType.UploadProgress:
                                    file.progress = Math.round(event.loaded * 100 / event.total);
                                    break;
                              case HttpEventType.Response:
                                    return event;
                        }
                  }),
                  tap(message => { }),
                  last(),
                  catchError((error: HttpErrorResponse) => {
                        file.inProgress = false;
                        file.canRetry = true;
                        return of(`${file.data.name} upload failed.`);
                  })
            ).subscribe(
                  (event: any) => {
                        if (typeof (event) === 'object') {
                              this.removeFileFromArray(file);
                              this.complete.emit(event.body);
                        }
                  }
            );
      }

      private uploadFiles() {
            const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
            fileUpload.value = '';

            this.files.forEach(file => {
                  this.uploadFile(file);
            });
      }

      private removeFileFromArray(file: FileUploadModel) {
            const index = this.files.indexOf(file);
            if (index > -1) {
                  this.files.splice(index, 1);
            }
      }
      cancel(){
            this.imageChangedEvent =undefined;  
      }

      fileChangeEvent(event: any): void {
            this.imageChangedEvent = event;
      }
      imagefile: any;
      imageCropped(event: ImageCroppedEvent) {
            console.log("event>>>>>>>>>>>>>>>>>>>>>>",event);
            this.croppedImage = event.base64;

            this.imagefile = new File([this.dataURItoBlob(this.croppedImage)], "dp.png"
                  , { type: 'image/png' });
 
                  let data:any={
                        data:this.imagefile,
                        state: "",
                       progress: 0,
                      
                  };
                  this.uploadFile(data);
      }


      imageLoaded() {
            // show cropper
      }
      cropperReady() {
            // cropper ready
      }
      loadImageFailed() {
            // show message
      }

      dataURItoBlob(dataURI) {
            const byteString = window.atob(dataURI.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const int8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
                  int8Array[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([int8Array], { type: 'image/jpeg' });
            return blob;
      }
}

export class FileUploadModel {
      data: File;
      state: string;
      inProgress: boolean;
      progress: number;
      canRetry: boolean;
      canCancel: boolean;
      sub?: Subscription;
}