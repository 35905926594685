import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SearchTermService } from './brodcast/searchterm';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { CdkScrollable } from '@angular/cdk/overlay';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'talent-box';
  @Input() type: string;
  term:any;
  subscription: Subscription;
  constructor(private searchTermService:SearchTermService,private router:Router) { 
    // subscribe to home component messages
    this.subscription = this.searchTermService.getMessage().subscribe(message => {

      console.log(`from nav`, message);
      console.log("message>>>>>>>>>>>>>>>>>>>>>>", message);
      if (message) {

        this.image = message;
      } else {
        // clear messages when empty message received
        this.image = localStorage.getItem("userImagePath");
      };
      this.updatemenu();
    });
   }

  ngOnInit() {
   this.  updatemenu();
  }
  //userImagePath
  image:any;
  authid=localStorage.getItem("authId");
  updatemenu(){
    this.authid=localStorage.getItem("authId");
    if(this.authid)
    {
      this.image=localStorage.getItem("userImagePath");
    }
    if(!this.authid)
    {
     // this.router.navigateByUrl('/login');
      localStorage.setItem("userImagePath","assets/images/user.jpg");
    this.image=localStorage.getItem("userImagePath");
    }
  }
  sendMessage(x): void {
   if(x!="" || x!=undefined)
    this.router.navigateByUrl('/search/'+x);
 
}
@ViewChild(CdkScrollable) scrollable: CdkScrollable;
onActivate(event) {
  //window.scroll(0,0);
   //document.body.scrollTop =-500;
    //let pos = window.pageYOffset;
   //document.querySelector('#maindiv').scrollTo(0,0)
  //...

  //document.querySelector('.mat-sidenav-content').scrollTop = 0;

    
}

clearMessages(): void {
    // clear messages
    this.searchTermService.clearSearchMessages();
}

logout(){
  localStorage.removeItem("authId");
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("userImagePath");
  this.authid=null;
  this.router.navigateByUrl('/home');
}
}
