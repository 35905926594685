<div id="myNav" class="overlay">
  <div style="text-align:center">
    <div style="    margin-left: auto;
    margin-right: auto;
    height: 80vh;
    text-align: center;
    padding-top: 20vh;">
      <div class="loader" style="margin-left: auto;margin-right: auto; "> </div>
        <img  style="margin-top:-15vh; position:absolute; margin-left:-50px; margin-right:auto;"
             src="assets/images/big-logo.png" height="30" class="slogo" alt="Talent box x Logo" />
     
    </div>
  </div>

</div>
