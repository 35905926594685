import { Component, OnInit, Input } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpService } from '../http.service';
import { Globals } from '../global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../dialog/dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-vidcard',
  templateUrl: './vidcard.component.html',
  styleUrls: ['./vidcard.component.css']
})
export class VidcardComponent implements OnInit {
  @Input() inparentitem;
  id: any;
  BASE_URL = this.Globals.server;
  constructor(public service: HttpService, private Globals: Globals,
    public snackBar: MatSnackBar, private router: Router,
    private route: ActivatedRoute, private sanitizer: DomSanitizer, public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) { }
  ngOnInit() {
    this.parentitem=this.inparentitem;
  }

  geturl(x) {
    return "http://api.talentboxx.com//uploads/" + x.thumb;
  }
  openDialog(x): void {
    //b.preventDefault();
    this.setView(x);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '800px',
      maxHeight: '90vh',
      data: x
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }

  isloading = false;
  challenges: any;
  url: any;
  thumburl: any;
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'todos-notification-overlay'
    });
  }
  parentitem:any;
  getnew(x) {
    this.isloading=true;
    this.service.get(`/GetNextVids?id=${x.id}&ln=${x.files.length}`).subscribe(data => {
     
      console.log("data ------------",data);
      // data.forEach(element => {
      //   element.safeexternal = this.sanitize(element.external);
      //   x.files.push(element );
      // })
      this.isloading=false;
     
      console.log("x ------------",x);
    });
    
  }
  setView(id) {

    this.service.getWithAuth("/FileViews?add=1&id=" + id.fileid).subscribe(
      (data) => {
        if (data.status == 0) {
          this.isloading = false;
          id.views = id.views + 1;
          console.log("file", id);
          this.challenges = data.message;
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }
  errorHandler(event: any) {
    event.target.src = "../../assets/images/video-placeholder.png";
  }
}
