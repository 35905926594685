import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

import { NavComponent } from './nav/nav.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
//import { MatFileUploadModule } from 'angular-material-fileupload';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatChipsModule } from '@angular/material/chips';

import { NgxSocialShareModule } from 'ngx-social-share';

import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent, GetPasswordDialog, BottomSheetOverviewExampleSheet } from './auth/profile/profile.component';
import { LogoComponent } from './shared/logo/logo.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SearchnavComponent } from './shared/searchnav/searchnav.component';
import { ShowcaseComponent } from './showcase/showcase.component';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { MatVideoModule } from 'mat-video';

import { ShareButtonsModule } from '@ngx-share/buttons';


import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './shared/http.service';
import { Globals } from './shared/global';
import { UploadComponent } from './auth/upload/upload.component';
import { MyuploadsComponent } from './auth/myuploads/myuploads.component';
import { DialogComponent } from './shared/dialog/dialog.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { SearchShowcaseComponent } from './searchresult/showcase.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { SearchTermService } from './brodcast/searchterm';
import { ImagecropperComponent } from './shared/imagecropper/imagecropper.component';
import { TruncatePipe } from './shared/truncatepipe';
//import { YouTubePlayerModule } from '@angular/youtube-player';
import { H401Interceptor } from './shared/secure.service';
import { VideoSlateComponent } from './video-slate/video-slate.component'

import { VidcardComponent } from './shared/vidcard/vidcard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FlexModule } from './flexmodule/flexmodule.module'
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { ShareSheet } from './video-slate/share/share';
import { ContactComponent } from './shared/contact/contact.component';
import { TermsComponent } from './shared/terms/terms.component';
import { PrivacyComponent } from './shared/privacy/privacy.component';
import { PastvidComponent } from './pastvid/pastvid.component';
import { VideoLinkComponent } from './video-link/video-link.component';
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    RegisterComponent,
    LoginComponent,
    ProfileComponent,
    LogoComponent,
    FooterComponent,
    SearchnavComponent,
    ShowcaseComponent,
    UploadComponent,
    MyuploadsComponent,
    DialogComponent,
    LoaderComponent,
    SearchShowcaseComponent,
    GetPasswordDialog,
    FileUploadComponent,
    BottomSheetOverviewExampleSheet,
    ImagecropperComponent,
    TruncatePipe,
    VideoSlateComponent,
    VidcardComponent,
    ShareSheet,
    ContactComponent,
    TermsComponent,
    PrivacyComponent,
    PastvidComponent,
    VideoLinkComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    ShareButtonsModule,
    //YouTubePlayerModule,
    MatVideoModule,
    //JwSocialButtonsModule ,
    FlexModule,
    MatChipsModule,
    NgxSocialShareModule,

    MatButtonToggleModule,
    MatGridListModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSnackBarModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDividerModule,
    MatProgressBarModule,
    MatBottomSheetModule,
    MatCarouselModule,
    // MatFileUploadModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule

  ],
  exports: [DialogComponent, LoaderComponent, GetPasswordDialog, ImagecropperComponent, TermsComponent,
    FileUploadComponent, BottomSheetOverviewExampleSheet],
  entryComponents: [DialogComponent, ShareSheet, LoaderComponent, ImagecropperComponent, GetPasswordDialog, FileUploadComponent, BottomSheetOverviewExampleSheet],
  providers: [HttpService, SearchTermService, H401Interceptor,
    Globals, { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
