<h1 mat-dialog-title>{{data.title}} <small>@{{data.uid}} | {{data.date|date}}</small></h1>
<div mat-dialog-content>
    <iframe width="750" height="400" [src]="sanitize(external)" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <video *ngIf="external && external.length>0" id="{{videoPlayer?.id}}"
        class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto" data-setup='{"fluid": "false"}'>
        <source src="{{videoPlayer?.url}}" type="{{videoPlayer?.sourceType}}">
    </video>

    <mat-video *ngIf="!external" [src]="url" [poster]="thumburl" [download]="false" color="accent" spinner="spin">
    </mat-video>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">
        <div class="heart" onclick="javascript:this.classList.toggle('active')"></div>
    </button>
    <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Close</button>
</div>