import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchTermService {
    private subject = new Subject<any>();
  private searcht = new Subject<any>();

  //################# for user challenger//
  private userchallenges = new Subject<any>();

  //update
  senduserchallenges(challenges: any) {
    this.userchallenges.next(challenges);
  }

  //clear
  clearuserchallenges() {
    this.userchallenges.next();
  }

  //get
  getuserchallenges(): Observable<any> {
    return this.userchallenges.asObservable();
  }

  //######################### ------ END ----------#####
    sendMessage(searchterm: string) {
       
        this.subject.next( searchterm );
    }

    clearMessages() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    sendSearchMessage(searchterm: string) {
       
        this.searcht.next( {text:searchterm} );
    }

    clearSearchMessages() {
        this.searcht.next();
    }

    getSearchMessage(): Observable<any> {
        return this.searcht.asObservable();
    }
}
