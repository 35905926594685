<footer class="footer-section">
    <div class="container-fluid">
        <div class="extraspace"></div>
        <div class="row">
            <div class="col-md-12 text-center">
                <img [routerLink]="'/home'" src="assets/images/big-logo.png" class="XSmall_Logo">
            </div>
            <br>
            <div class="col-md-12 text-center padding-top5">
                <span class="footer-text">All rights reserved TalentBoxx 2020</span>
            </div>

            <div class="col-md-6 text-center padding-top5">
                <span class="footer-text footer-menu" [routerLink]="'/about'">About Us</span>
                <span class="footer-text footer-menu" [routerLink]="'/contact'">Contact Us</span>
                <span class="footer-text footer-menu" [routerLink]="'/terms'">Terms and Condition</span>
                <span class="footer-text footer-menu" [routerLink]="'/privacy'">Privacy and Legal</span>

                <!-- <span class="footer-text">About Us</span>
          <span class="footer-text">About Us</span>
          <span class="footer-text">About Us</span> -->
            </div>

        </div>

    </div>
</footer>