import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpService } from '../shared/http.service';
import { Globals } from '../shared/global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ShareSheet } from '../video-slate/share/share';
declare let videojs: any;
@Component({
  selector: 'app-pastvid',
  templateUrl: './pastvid.component.html',
  styleUrls: ['./pastvid.component.css']
})
export class PastvidComponent implements OnInit {

  id: any;
  BASE_URL = this.Globals.server;
  constructor(public service: HttpService, private Globals: Globals,
    public snackBar: MatSnackBar, private router: Router,
    private route: ActivatedRoute, private sanitizer: DomSanitizer, public dialog: MatDialog,
    private activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet
  ) { }
  videoId: any;
  queryid: any;
  video: any;

  openBottomSheet(x): void {
    const bottomSheetRef = this._bottomSheet.open(ShareSheet, {
      data: x,
    });

  }

  videodata: any = {};
  isloading = false;
  @ViewChild('maindiv') divToScroll: ElementRef;
  ngOnInit() {
    this.getChallenges();
    this.activatedRoute.params.subscribe((x) => {
      console.log("params", this.route.snapshot.paramMap + ` other param ${JSON.stringify(x)}`);
      this.id = x.id;
      this.getChallenge(this.id);

      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started

    });
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  userChallenges: any; pastChallenges: any;
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'todos-notification-overlay'
    });
  }
  url = "";
  add: any;
  currentplay: any;
  setFav() {
    if (!localStorage.getItem("authId")) {
      this.showNotification("Please sign in/register to like a video");
      return false;
    }
    if (this.add == 0) { this.add = 1; }
    else { this.add = 0; }
    this.service.getWithAuth("/FileFavs?add=" + this.add + "&id=" + this.currentplay.fileid).subscribe(
      (data) => {
        if (data.status == 0) {
          this.isloading = false;
          this.currentplay.favs = this.currentplay.favs - 1;

        }
        else {
          this.currentplay.favs = this.currentplay.favs + 1;
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }
  setView(id) {

    this.service.getWithAuth("/FileViews?add=1&id=" + id.fileid).subscribe(
      (data) => {
        if (data.status == 0) {
          this.isloading = false;
          id.views = id.views + 1;
          console.log("file", id);
          // this.challenges=data.message;
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }

  getChallenge(id) {
    this.isloading = true;
    this.service.getWithAuth("/GetAllChallenges?id=" + id).subscribe(
      (data) => {
        if (data.status == 0) {

          this.currentplay = data.message[0];

          this.currentplay.thumburl = this.BASE_URL + data.message[0].thumb;
          this.currentplay.url = data.message[0].external;
          this.videoPlayer.url = data.message[0].external;
          this.videoPlayer.id = this.makeid(15);
          this.videoPlay();
          
          this.setView(this.currentplay);
          this.isloading = false;
          /*this.currentplay.files.forEach(element => {
            element.safeexternal = this.sanitize(element.external);
          });*/
          // alert(   document.querySelector('.mat-sidenav-content').scrollTop);
          document.querySelector('.mat-sidenav-content').scrollTop = 0;
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }
  getChallenges() {
    this.isloading = true;
    this.service.getWithAuth("/GetAllChallenges").subscribe(
      (data) => {
        if (data.status == 0) {

          this.userChallenges = data.message;
          if (data.pastchallenges.length > 0)
            this.pastChallenges = data.pastchallenges;

          var data = data.message[0];

          console.log("this.userChallenges", this.userChallenges);

          /*this.userChallenges.forEach(element => {
            element.safeexternal = this.sanitize(element.external);
            element.files.forEach(x => {
              x.safeexternal = this.sanitize(x.external)
            });
          });*/

          /*this.pastChallenges.forEach(element => {
            element.safeexternal = this.sanitize(element.external);
            element.files.forEach(x => {
              x.safeexternal = this.sanitize(x.external)
            });
          });*/

          console.log("sanitize", this.url);
          this.isloading = false;


        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }

  videoPlayer: any = {
    url: '',
    id: '',
    sourceType: 'application/x-mpegURL'
  };

  videoPlay() {
    setTimeout(() => {
      var player = videojs(this.videoPlayer.id);
      player.src(this.videoPlayer.url);
      player.load();
    }, 100);
  }

  makeid(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}

