import {Component, Inject} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';


@Component({
  selector: 'sharesheet',
  templateUrl: './share.html',
})
export class ShareSheet {
  constructor(private _bottomSheetRef: MatBottomSheetRef<ShareSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
        console.log("data",data);
    }

  
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}