import { Component, OnInit ,Inject} from '@angular/core';
import { HttpService } from '../../shared/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {MatDatepickerInputEvent} from '@angular/material/datepicker'
import { Globals } from '../../shared/global';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchTermService } from 'src/app/brodcast/searchterm';
import { ImagecropperComponent } from 'src/app/shared/imagecropper/imagecropper.component';
export interface DialogData {
  currentpassword: string;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private searchTermService:SearchTermService,public service: HttpService,private Globals: Globals,
    public snackBar: MatSnackBar, private router: Router,private sanitizer: DomSanitizer,
    private route: ActivatedRoute,public dialog: MatDialog,private _bottomSheet: MatBottomSheet) { }
  vprof = false;
    sanitize(url: string) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    logout(){
      localStorage.removeItem("authId");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("userImagePath");
      this.router.navigateByUrl('/home');
    }
    openBottomSheet(x): void {
      let text="";
      if(x==0)
      {
        text="TalentboxX Account"
      }else{
        text="Profile Picture";
      }
      const _bottomSheetRef = this._bottomSheet.open(BottomSheetOverviewExampleSheet, {
                data:{accept: false,text:text}
      });
  
      _bottomSheetRef.afterDismissed().subscribe((result) => {
        console.log('The dialog was closed',result);
        //this.animal = result;
        let api="api/Account/removeAccount";
        if(x==1)
        {
          api="api/Account/removeImages"
        }else{
          api="api/Account/removeAccount";
        }{
          this.service.getWithAuth("/"+api+"?pass="+this.userdetails.currentpassword).subscribe((data) => {
          
            if(data.status==0)
            {
              if(x==0)
              {
               this.logout();
              }
            this.userImagePath="assets/images/user.jpg";
            this.searchTermService.sendMessage( this.userImagePath);
            localStorage.setItem("userImagePath", this.userImagePath)
            }
            else
            {
              this.showNotification(data.message);
            }
          });
        }
      });
    
    }

  public user: any = {};
  submitted: any;
  userImagePath="assets/images/user.jpg";
  ngOnInit() {
    this.gettUser();
    
  }
  editer=false;
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'todos-notification-overlay'
    });
  }

  videodata:any={};
  openDialog(x): void {
    this.setView(x);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '650px',
      data: x
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }
  changeDp(): void {
    
    const dialogRef = this.dialog.open(ImagecropperComponent, {
      width: '100%',
      height:'100%',
      data: {}
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log("result upload>>>>>>>.",data);
   this.onFileComplete(data);
    });
  }
  getPAssword(): void {
    
    const dialogRef = this.dialog.open(GetPasswordDialog, {
      width: '250px',
      data: {currentpassword: this.userdetails.currentpassword}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.userdetails.currentpassword = result;
      this.userdetails.password = result;
      if(this.userdetails.currentpassword && this.userdetails.currentpassword.length>5)
      this.editer=true;
      else
      this.showNotification("Please enter proper Password");
    });
  }
  isloading: any;
  userdetails:any;

  gettUser() {
    this.isloading = true;
    this.service.getWithAuth("/api/account/GetUserInfo").subscribe(
      (data) => {
        console.log("login data", data);
        console.log("login data", data.error);
        if (data.status==0) {
          this.isloading = false;
          this.userdetails=data;
          if(data.userimage==1)
          {
            this.userImagePath=this.Globals.server+"/uploads/"+data.uimg;
          }
          // this.showNotification(data.message);
          //this.router.navigate(['/profile']);
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
        this.showNotification(error.error.error_description);
      }
    )
  }
 
  onFileComplete(data: any) {
    console.log(data); // We just print out data bubbled up from event emitter.
    this.userImagePath=this.Globals.server+"/uploads/"+data.message;
    this.searchTermService.sendMessage(this.Globals.server+"/uploads/"+data.message);
    localStorage.setItem("userImagePath",this.Globals.server+"/uploads/"+data.message)
    console.log(  "  this.userImagePath",this.userImagePath);
  }
  
  updateUser() {
    this.isloading = true;
    this.submitted=true;
    let data=new Date( this.userdetails.dob);
    this.userdetails.dob= data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate();
    this.service.postWithAuth("/api/account/UpdateUserInfo",this.userdetails).subscribe(
      (data) => {
        console.log("login data", data);
        console.log("login data", data.error);
        if (data.status==0) {
          this.isloading = false;
          this.editer=false;
           this.showNotification("Profile updated succesfully");
           this.userdetails.currentpassword ="";
         // this.router.navigate(['/profile']);
        }
        else {
          this.isloading = false;
this.editer=false;
          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
        this.editer=false;
        this.showNotification(error.error.error_description);
      }
    )
  }


  userChallenges:any;
  geturl(x)
  {
    return this.Globals.server+"/uploads/"+x.thumb;
  }
 


  submit() {
    // alert("called");
    this.submitted = true;
  }
  challenges:any;
  setView(id)
  {
    
    this.service.getWithAuth("/FileViews?add=1&id="+id.fileid).subscribe(
      (data) => {
        if (data.status==0) {
          this.isloading = false;
          id.views=id.views+1;
          console.log("file",id);
          this.challenges=data.message;
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }

}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  <div mat-dialog-content>
    <p>What's your current password?</p>
    <mat-form-field>
      <mat-label>current password</mat-label>
      <input type="password" matInput [(ngModel)]="data.currentpassword">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data.currentpassword" cdkFocusInitial>Ok</button>
  </div>`,
})
export class GetPasswordDialog {

  constructor(
    public dialogRef: MatDialogRef<GetPasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'bottom-sheet-overview-example-sheet',
  template: `<div >
  <h2>Are you sure?</h2>
  <h3>You want to remove {{data.text}}</h3>
</div>
<div>
  <button mat-button (click)="onNoClick(false)">Cancel</button>
  <button mat-button  (click)="onNoClick(true)"  cdkFocusInitial>Ok</button>
</div>`,
})
export class BottomSheetOverviewExampleSheet {
  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewExampleSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { 
      console.log("data2@@@@@@@@@@@@@@@@@",data)
      }
    
      
  onNoClick(x): void {
    this.data={accept:x};
    console.log("this.data----------------",this.data);
  
    this._bottomSheetRef.dismiss( this.data);
    event.preventDefault();
  }
}
