import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {
      HttpClient, HttpResponse, HttpRequest,
      HttpEventType, HttpErrorResponse, HttpHeaders
} from '@angular/common/http';
import { Subscription, of } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { Globals } from '../global';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import {FileUploadModel} from '../file-upload/file-upload.component'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-imagecropper',
  templateUrl: './imagecropper.component.html',
  styleUrls: ['./imagecropper.component.css'],
  animations: [
    trigger('fadeInOut', [
          state('in', style({ opacity: 100 })),
          transition('* => void', [
                animate(300, style({ opacity: 0 }))
          ])
    ])
]
})
export class ImagecropperComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  constructor(public dialogRef: MatDialogRef<ImagecropperComponent>, @Inject(MAT_DIALOG_DATA) public data:any,private _http: HttpClient, private Globals: Globals, 
    ) {  }

  ngOnInit() {
  }
  files: Array<FileUploadModel> = [];
  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
          for (let index = 0; index < fileUpload.files.length; index++) {
                const file = fileUpload.files[index];
                this.files.push({
                      data: file, state: 'in',
                      inProgress: false, progress: 0, canRetry: false, canCancel: true
                });
          } this.imageChangedEvent = event;
          //this.uploadFiles();
    };
    fileUpload.click();
}

cancelFile(file: FileUploadModel) {
    file.sub.unsubscribe();
    this.removeFileFromArray(file);
}

retryFile(file: FileUploadModel) {
    this.uploadFile(file);
    file.canRetry = false;
}
@Input() text = 'Edit Image';
/** Name used in form which will be sent in HTTP request. */
@Input() param = 'file';
/** Target URL for file uploading. */
@Input() target = 'https://file.io';
/** File extension that accepted, same as 'accept' of <input type="file" />. 
    By the default, it's set to 'image/*'. */
@Input() accept = 'image/*';
/** Allow you to add handler after its completion. Bubble up response text from remote. */
@Output() complete = new EventEmitter<string>();
private uploadFile(file: FileUploadModel) {
    const fd = new FormData();
    fd.append(this.param, file.data);
    let AUTHHEADER = new HttpHeaders().append('Authorization', `Bearer ${localStorage.getItem("authId")}`);
    const req = new HttpRequest('POST', this.Globals.server + "/UploadImages" , fd, {
          reportProgress: true, headers: AUTHHEADER
    });

    file.inProgress = true;
    file.sub = this._http.request(req).pipe(
          map(event => {
                switch (event.type) {
                      case HttpEventType.UploadProgress:
                            file.progress = Math.round(event.loaded * 100 / event.total);
                            break;
                      case HttpEventType.Response:
                            return event;
                }
          }),
          tap(message => { }),
          last(),
          catchError((error: HttpErrorResponse) => {
                file.inProgress = false;
                file.canRetry = true;
                return of(`${file.data.name} upload failed.`);
          })
    ).subscribe(
          (event: any) => {
                if (typeof (event) === 'object') {
                  this.onNoClick(event.body);
                      this.removeFileFromArray(file);
                      this.complete.emit(event.body);
                      console.log("event",event);
                   
                    
                }
                
          }
    );
}
onNoClick(body): void {
  if(body.message)
  {
  console.log("bidy=============",body);
  this.data=body;
  this.dialogRef.close(this.data);
  }
}

private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
          this.files.splice(index, 1);
    }
}
cancel(){
    this.imageChangedEvent =undefined;  
    this.dialogRef.close();
}

fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
}
imagefile: any;
imagedata:any;

private uploadFiles() {
  const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
  fileUpload.value = '';

  this.files.forEach(file => {
        this.uploadFile(file);
  });
}

imageCropped(event: ImageCroppedEvent) {
    console.log("event>>>>>>>>>>>>>>>>>>>>>>",event);
    this.croppedImage = event.base64;

    this.imagefile = new File([this.dataURItoBlob(this.croppedImage)], "dp.jpg"
          , { type: 'image/jpeg' });

          let data:any={
                data:this.imagefile,
               state: 'in',
               inProgress: false, progress: 0, canRetry: false, canCancel: true
          };
         // this.imagedata=data;
         this.files=[];
          this.files.push(data);
         // this.uploadFile(data);
}
save()
{
   return  this.uploadFiles();
}

imageLoaded() {
    // show cropper
}
cropperReady() {
    // cropper ready
}
loadImageFailed() {
    // show message
}
canvasRotation = 0;
rotation = 0;
scale = 1;
showCropper = false;
containWithinAspectRatio = false;
transform: ImageTransform = {};
rotateLeft() {
  this.canvasRotation--;
  this.flipAfterRotate();
}

rotateRight() {
  this.canvasRotation++;
  this.flipAfterRotate();
}

private flipAfterRotate() {
  const flippedH = this.transform.flipH;
  const flippedV = this.transform.flipV;
  this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
  };
}


flipHorizontal() {
  this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
  };
}

flipVertical() {
  this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
  };
}

resetImage() {
  this.scale = 1;
  this.rotation = 0;
  this.canvasRotation = 0;
  this.transform = {};
}

zoomOut() {
  this.scale -= .1;
  this.transform = {
      ...this.transform,
      scale: this.scale
  };
}

dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
}
}
