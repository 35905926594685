import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../shared/http.service';
import { Globals } from '../shared/global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { SearchTermService } from '../brodcast/searchterm';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchShowcaseComponent implements OnInit {
  subscription: Subscription;
  BASE_URL = this.Globals.server;
  searchterm: any;
  id: any;
  constructor(private ref: ChangeDetectorRef, public service: HttpService, private Globals: Globals, private searchTermService: SearchTermService,
    public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute, private sanitizer: DomSanitizer, public dialog: MatDialog) {

    this.activatedRoute.params.subscribe((x) => {
      console.log("params", this.route.snapshot.paramMap + ` other param ${JSON.stringify(x)}`);
      this.searchterm = x.id;
      this.getChallenges();
      window.scrollTo(0, 0);
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started

    });

  }
  videoId: any;
  queryid: any;
  video: any;

  videodata: any = {};
  openDialog(x): void {
    this.setView(x);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '800px',
      maxHeight: '90vh',
      data: x
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }
  setView(id) {
    if (!localStorage.getItem("authId")) {
      this.showNotification("Please sign in/register to like a video");
      return false;
    }

    this.service.getWithAuth("/FileViews?add=1&id=" + id.fileid).subscribe(
      (data) => {
        if (data.status == 0) {
          this.isloading = false;
          id.views = id.views + 1;
          console.log("file", id);
          this.challenges = data.message;
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }
  ngOnInit() {

  }
  url: any;
  thumburl: any;
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'todos-notification-overlay'
    });
  }
  challenges: any;
  isloading: any;
  baseurl = "http://tbxa.btrx.in/uploads/";
  title: any; desc: any; cat: any; date: any;
  userChallenges: any;
  getChallenges() {
    this.isloading = true;
    this.service.getWithAuth("/GetFilteredChallenges?term=" + this.searchterm).subscribe(
      (data) => {
        if (data.status == 0 && data.message.length > 0) {

          this.userChallenges = data.message;
          /*this.userChallenges.forEach(element => {element.safeexternal= this.sanitize(element.external);
            element.files.forEach(x => {x.safeexternal= this.sanitize(x.external)
            });
          });*/
          this.isloading = false;
          this.ref.detectChanges();
        }
        else {
          this.isloading = false;

          this.showNotification("No records found");
          this.ref.detectChanges();
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }
  geturl(x) {
    return "http://api.talentboxx.com//uploads/" + x.thumb;
  }
  GetFile(id) {
    this.service.get("/GetFilleDetails?id=" + id).subscribe(
      (data) => {
        this.video = data.result;

        this.url = `${this.BASE_URL}/images/${this.video.url}`;
        this.thumburl = `${this.BASE_URL}/images/${this.video.thumb}.jpeg`;
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    )
  }
  public show: boolean = true;
  errorHandler(event: any) {
    event.target.src = "../../assets/images/video-placeholder.png";
  }
}
