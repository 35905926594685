import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare let videojs: any; // just to avoid TS stuffs for this demo
@Component({
  selector: 'app-video-link',
  templateUrl: './video-link.component.html',
  styleUrls: ['./video-link.component.css']
})
export class VideoLinkComponent implements OnInit {
  @Input('id') id: any;
  @Input('url') url: SafeResourceUrl;
  @Input('sourceType') sourceType: string;
  constructor() {
  }

  ngOnInit(): void {
    this.id = this.makeid(15);
    setTimeout(() => {
      videojs(this.id, {
        sources: [
          {
            src: this.url,
            type: this.sourceType
          }],
      });
    }, 100);
  }

  makeid(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  // sanitize(url: any) {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  // }
}
