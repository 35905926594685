<mat-nav-list>
    <a title="send to Facebook" mat-list-item
        href="http://www.facebook.com/sharer.php?u=http://x.talentboxx.com/home/share/{{data}}" target="_blank">
        <span>
            <img height="40" src="/assets/fb.png" alt="Facebook" />
        </span>
    </a>

    <a title="send to Facebook" mat-list-item
        href="http://twitter.com/share?text=Talentbox Latest Video&url=http://x.talentboxx.com/home/share/{{data}}"
        target="_blank">
        <span>
            <img height="40" src="/assets/tw.png" alt="Facebook" />
        </span>
    </a>

    <a href="http://x.talentboxx.com/home/share/{{data}}" mat-list-item (click)="openLink($event)">
        <span mat-line>Share Url</span>
        <span mat-line>http://x.talentboxx.com/home/share/{{data}}</span>
    </a>


</mat-nav-list>