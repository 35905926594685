<div class="login">
    <app-nav></app-nav>

    <div style="width: 95%;" class="vertical-center">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top" fxLayoutGap="10px">
            <div fxFlex="0 1 calc(66.6% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
                <img style="padding:10px;" width="50%" [routerLink]="'/home'" src="assets/images/logo-tag.png">
            </div>
            <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
                <form style="padding:10px;" name="register-form" #registerForm="ngForm" (ngSubmit)="postUser(registerForm)">
                    <!-- <mat-chip-list *ngIf="shworesend1">
                            <mat-chip (click)="resend()">Account not verified click here to resend Confirmation Email</mat-chip>
                        </mat-chip-list> -->
                    <div class="form-group">
                        <input type="text" [ngClass]="{error: uid.errors && submitted}" required name="uid" class="form-control" [(ngModel)]="user.username" #uid="ngModel" id="email" placeholder="User id / Registered Email Id">
                    </div>
                    <div class="form-group">
                        <input type="password" [ngClass]="{error: pass.errors && submitted}" required name="pass" [(ngModel)]="user.password" #pass="ngModel" class="form-control" id="pwd" placeholder="Password">
                    </div>
                    <div *ngIf="shworesend" class="form-group">
                        <button mat-button type="button" (click)="resend()">Resend Verification Email</button>
                    </div>
                    <div class="text-center">
                        <img *ngIf="isloading" src="assets/images/talent-box-loading.gif" width="60px" class="text-center">
                        <button *ngIf="!isloading" type="submit" class="btn btn1">Sign In</button>
                        <br/>
                        <p>Not a member?
                            <button mat-button [routerLink]="'/register'">Register Now</button>
                            <button mat-button type="button" (click)="forgotpassword()">Forgot Password</button>
                        </p>
                    </div>

                </form>
            </div>
        </div>



    </div>




    <footer class="footer1">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 text-center padding-top5">
                    <span class="footer-text">All rights reserved TalentBoxx 2020</span>
                </div>
                <div>
                    <span class="footer-text footer-menu" [routerLink]="'/about'">About Us</span>
                    <span class="footer-text footer-menu" [routerLink]="'/contact'">Contact Us</span>
                    <span class="footer-text footer-menu" [routerLink]="'/terms'">Terms and Condition</span>
                    <span class="footer-text footer-menu" [routerLink]="'/privacy'">Privacy and Legal</span>

                </div>
            </div>

        </div>
    </footer>
</div>