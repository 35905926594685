import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/http.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private service:HttpService) { }

  ngOnInit() {
    this.GetAbout();
  }
  about:any;
  GetAbout() {
    this.service.get("/GetAbout").subscribe(
      (data) => {
        this.about = data.message;
        console.log("GetAbout",data);
      },
      (error) => {
        console.log(error);
      }
    )
  }
}
