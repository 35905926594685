import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(public service: HttpService,
    public snackBar: MatSnackBar, private router: Router,
    private route: ActivatedRoute) { }
  public user: any = { gender: 1 };
  submitted: any;
  startDate:any;
  ngOnInit() {
    this.startDate=new Date(new Date().getFullYear()-14,0,1);
  }
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'todos-notification-overlay'
    });
  }
  isloading: any;
  postUser(registerForm) {
    if(!this.user.terms)
    {  this.showNotification("Please accept terms to proceed");
    return false;

    }
    console.log("registerForm",registerForm);
    if(!this.user.dob){
      this.showNotification("Please select date of birth");
      return false;
    }
    this.submitted = true;
    let data= this.user.dob;
    this.user.dob= data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate();
    if (registerForm.valid) {
      this.isloading = true;
      this.user.confirmpassword=this.user.password;
      this.service.post("/api/Account/Register", this.user).subscribe(
        (data) => {
          if (data.status == 0) {
            this.isloading = false;
            this.showNotification(data.message);
            this.router.navigate(['/login']);
          }
          else {
            this.isloading = false;

            this.showNotification(data.message);
          }
          console.log(data);
        },
        (error) => {
          console.log(error);
          this.isloading = false;
        }
      )
    }
  }
  panelOpenState=false;
  submit() {
    // alert("called");
    this.submitted = true;
  }
  closeExpansion(){this.panelOpenState = false}
  

}
