import { Component, OnInit, ViewChild, NgZone, Output, Input, EventEmitter } from '@angular/core';
import { HttpService } from '../../shared/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { ElementRef } from '@angular/core';
import { FileUploadModel } from 'src/app/shared/file-upload/file-upload.component';

import { trigger, state, style, animate, transition } from '@angular/animations';
import {
  HttpClient, HttpResponse, HttpRequest,
  HttpEventType, HttpErrorResponse, HttpHeaders, HttpParams
} from '@angular/common/http';
import { Subscription, of } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Globals } from '../../shared/global';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class UploadComponent implements OnInit {
  authid = localStorage.getItem("authId");

  BASE_URL = this.Globals.server;
  constructor(public service: HttpService, private myElement: ElementRef,
    public snackBar: MatSnackBar, private router: Router, private sanitizer: DomSanitizer,
    private route: ActivatedRoute, private _ngZone: NgZone, private _http: HttpClient,
    private Globals: Globals) { }
  public user: any = {};
  submitted: any;
  //@ViewChild('uploadtalent') el: ElementRef;
  //uploadtalent:any;

  geturl(x) {
    return "http://api.talentboxx.com//uploads/" + x.thumb;
  }
  ngOnInit() {
    if (!this.authid) {
      this.router.navigateByUrl('/login');
    }
    else {
      this.gettUser();
      this.getChallenges();
    }

    // setTimeout(() => { this.scroll(this.el.nativeElement); }, 100);

  }
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'todos-notification-overlay'
    });
  }

  isloading: any;
  userdetails: any;
  fileUploadQueue: any;
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  scrooler() {
    // this.scroll(this.el.nativeElement);
  }
  filep: File;
  fileChange(event) {
    this.filep = event.target.files[0];
  }
  private uploadFiles() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.value = '';

    this.files.forEach(file => {
      file.canCancel = false;
      this.uploadFile(file);
    });
  }
  @Input() text = 'Edit Image';
  /** Name used in form which will be sent in HTTP request. */
  @Input() param = 'file';
  /** Target URL for file uploading. */
  @Input() target = 'https://file.io';
  /** File extension that accepted, same as 'accept' of <input type="file" />. 
      By the default, it's set to 'image/*'. */
  @Input() accept = 'video/*';
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<string>();

  files: Array<FileUploadModel> = [];
  imageChangedEvent: any;
  cancelFile(file) {
    var index = this.files.findIndex(x => x == file);
    this.files.splice(index, 1);
  }
  onClick() {
    if (this.curitem == null) {
      this.showNotification("please select Challenge above", "close");
      return false;
    }
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        console.log("file =============== ", file);
        if (file.size > 150000000) {
          this.showNotification("please select file less than 150mb", "close");
        }
        else {
          this.files = [];
          this.files.push({
            data: file, state: 'in',
            inProgress: false, progress: 0, canRetry: false, canCancel: true
          });
        }
      } this.imageChangedEvent = event;
      //this.uploadFiles();
    };
    fileUpload.click();
  }

  setchallenge(x) {
    this.curitem = x;
    this.request.challenge = x.id;
  }

  private uploadFile(file: FileUploadModel) {
    this.isloading = true;
    const fd = new FormData();
    fd.append(this.param, file.data);
    let params = new HttpParams();
    let AUTHHEADER = new HttpHeaders().append('Authorization', `Bearer ${localStorage.getItem("authId")}`);
    const req = new HttpRequest('POST', this.Globals.server + `/UploadFile?challenge=${this.request.challenge}&ChallengeTitle=${this.request.ChallengeTitle}&Description=${this.request.Description}`, fd, {
      reportProgress: true, headers: AUTHHEADER
    });

    file.inProgress = true;
    file.sub = this._http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      tap(message => { }),
      last(),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        file.canRetry = true;
        this.isloading = false;
        return of(`${file.data.name} upload failed.`);

      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          this.removeFileFromArray(file);
          this.complete.emit(event.body);
          this.showNotification("file uploaded succesfully");
          this.router.navigate(['/profile']);
          this.isloading = false;
        }
      }
    );
  }
  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }
  postFile(x) {
    this.submitted = true;
    console.log("Form === ", x);
    if (x.valid) {
      this.uploadFiles();
    }
  }

  request: any = {};
  challenges: any;
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getChallenges() {
    this.service.getWithAuth("/GetAllChallenges").subscribe(
      (data) => {
        if (data.status == 0) {
          this.isloading = false;
          this.challenges = data.message;//.slice(0,3);
          /*this.challenges.forEach(element => {
            element.safeexternal = this.sanitize(element.external);

          });*/
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }
  curitem = null;
  gettUser() {

    this.service.getWithAuth("/api/account/GetUserInfo").subscribe(
      (data) => {
        console.log("login data", data);
        console.log("login data", data.error);
        if (data.status == 0) {
          this.isloading = false;
          this.userdetails = data;
          // this.showNotification(data.message);
          // this.router.navigate(['/profile']);
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
        this.showNotification(error.error.error_description);
      }
    )
  }



  submit() {
    // alert("called");
    this.submitted = true;
  }

}
