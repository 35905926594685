<div class="mainbg">
    <app-nav></app-nav>

    <div class="container-fluid text-center">
        <app-logo></app-logo>
    </div>
</div>
<div class="container" *ngIf="about">
    <div class="row content">
        <h1 class="h1">About us</h1>
        <p class="text2">
            {{about.Title}} </p>

        <h1 class="h1">How it works</h1>
    </div>
    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top" fxLayoutGap="10px">
        <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
            <mat-card class="example-card">
                <img src="assets/images/video-camera.png" alt="Photo of a Shiba Inu">
                <mat-card-content>
                    <h4 class="text3">RECORD AND UPLOAD</h4>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
            <mat-card class="example-card">
                <img src="assets/images/team.png" alt="Photo of a Shiba Inu">
                <mat-card-content>
                    <h4 class="text3">THE WORLD VOTES</h4>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
            <mat-card class="example-card">
                <img src="assets/images/trophy.png" alt="Photo of a Shiba Inu">
                <mat-card-content>
                    <h4 class="text3">MOST LOVED SUMBISSION WIN</h4>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>




<app-footer></app-footer>