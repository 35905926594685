import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { HttpService } from '../http.service';
import { MatSnackBar } from '@angular/material/snack-bar';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  profileForm = new FormGroup({
    uname: new FormControl('', [
      Validators.required,
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    message: new FormControl('', [
      Validators.required,
    ])
  });

  get uname() {
    return this.profileForm.get('uname')!;
  }

  get email() {
    return this.profileForm.get('email')!;
  }
  get message() {
    return this.profileForm.get('message')!;
  }

  constructor(private service:HttpService,private snackBar:MatSnackBar ) { }

  ngOnInit(): void {
  }

  isloading=false;
  matcher = new MyErrorStateMatcher();
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'todos-notification-overlay'
    });
  }

  onSubmit() {
  
    console.warn(this.profileForm.value);
    if(this.profileForm.valid)
    {  this.isloading = true;
    this.service.post("/postfeedback",this.profileForm.value).subscribe(x=>{
      this.showNotification("Thanks you so much , we have received your Feedback");
this.isloading=false;
    },error=>{});
  }
  }
}
