import { Component, OnInit, Input } from '@angular/core';
import { SearchTermService } from '../brodcast/searchterm';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  @Input() type: string;
  term: any;
  subscription: Subscription;
  constructor(private searchTermService: SearchTermService, private router: Router) {
    // subscribe to home component messages
    this.subscription = this.searchTermService.getMessage().subscribe(message => {

      console.log(`from nav`, message);
      console.log("message>>>>>>>>>>>>>>>>>>>>>>", message);
      if (message) {

        this.image = message;
      } else {
        // clear messages when empty message received
        this.image = localStorage.getItem("userImagePath");
      };
      this.updatemenu();
    });
  }
  authid: any;
  ngOnInit() {
    this.updatemenu();
  }
  //userImagePath
  image: any;
  updatemenu() {
    this.authid = localStorage.getItem("authId");
    if (this.authid) {
      this.image = localStorage.getItem("userImagePath");
    }
    if (!this.authid) {
      // this.router.navigateByUrl('/login');
      localStorage.setItem("userImagePath", "assets/images/user.jpg");
      this.image = localStorage.getItem("userImagePath");
    }
  }

  sendMessage(x): void {
    console.log("x===================", x);
    // send message to subscribers via observable subject

    let currentUrl = this.router.url;
    if (currentUrl != "/search") {
      this.router.navigateByUrl('/search');
      console.log("currentUrl=============", currentUrl);
      setTimeout(() => {
        this.searchTermService.sendSearchMessage(x);
      }, 100);

    }
  }

  clearMessages(): void {
    // clear messages
    this.searchTermService.clearSearchMessages();
  }

  logout() {
    localStorage.removeItem("authId");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("userImagePath");
    this.authid = null;
    this.router.navigateByUrl('/home');
  }
}
