<app-loader *ngIf="isloading"></app-loader>
<app-nav type="full"></app-nav>



<div class="propdivbottom">
    <h1 class="h1" *ngIf="userChallenges">{{userChallenges.length}} Found !!</h1>


    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top" fxLayoutGap="10px"
        *ngIf="userChallenges && userChallenges.length>0">
        <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%"
            *ngFor="let item of userChallenges">
            <mat-card class="example-card" [routerLink]="'/t/'+item.id">
                <mat-card-header>
                    <mat-card-title>{{item.title}}</mat-card-title>
                    <mat-card-subtitle>{{item.uname }}</mat-card-subtitle>
                </mat-card-header>
                <!-- <iframe *ngIf="item?.external?.includes('.m3u8') == false" class="iframe" width="100%" height="150"
                    [src]="item.safeexternal" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
                    <img src="../../assets/play.png" class="setBGPlayIcon" />
                <img [src]="item?.thumb" *ngIf="item?.thumb" class="placeHolderImage" (error)="errorHandler($event)" />
                <img src="../../assets/video-placeholder.png" *ngIf="!item?.thumb" class="placeHolderImage" />

                <mat-card-content>
                    <p>
                        {{item.description}}
                        <br /><span style="font-size: 10px;">{{item.date|date}}</span>
                    </p>
                </mat-card-content>
                <mat-card-actions [routerLink]="'/t/'+item.id">
                    <button mat-button>
                        <mat-icon>remove_red_eye</mat-icon>
                        {{item.views}}
                    </button>
                    <button mat-button>
                        <mat-icon>thumb_up_alt</mat-icon>
                        {{item.favs}}
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>

</div>

<app-footer></app-footer>