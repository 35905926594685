<app-loader *ngIf="isloading"></app-loader>
<app-nav type="full"></app-nav>
<div style="padding-top:20px;">

    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top" fxLayoutGap="10px"
        *ngIf="currentplay" fxFlexFill>
        <div fxFlex="1 3 calc(70% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
            <!-- <iframe *ngIf="currentplay?.url?.includes('.m3u8') == false" width="99%" allowTransparency="true" background="transparent" height="100%" [src]="currentplay.url"
                frameborder="0" autoplay="true"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe> -->

            <video id="{{videoPlayer?.id}}" class="video-js vjs-default-skin vjs-big-play-centered" controls
                preload="auto" data-setup='{"fluid": "false"}'>
                <source src="{{videoPlayer?.url}}" type="{{videoPlayer?.sourceType}}">
            </video>
        </div>
        <div fxFlex="0 1 calc(27% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
            <mat-card style="width:100%; ">
                <mat-card-header style="padding:10px;">
                    <div mat-card-avatar class="example-header-image"
                        [ngStyle]="{'background-image': 'url('+currentplay.uimg+')'}"></div>
                    <mat-card-title style="color: #4a148c;">{{currentplay.uname}} <span
                            style="font-size: 12px; font-weight:200;color:#8e24aa"> @{{currentplay.uid}}</span>
                    </mat-card-title>
                    <mat-card-subtitle style="margin-top: 10px;">
                        @{{currentplay.date|date}}
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <h1>{{currentplay.title}}</h1>

                    <p>{{currentplay.description}}</p>
                    <p>
                        <button mat-button>
                            <mat-icon>remove_red_eye</mat-icon>
                            {{currentplay.views}}
                        </button>
                        <button mat-button (click)="setFav()">
                            <mat-icon>thumb_up_alt</mat-icon>
                            {{currentplay.favs}}
                        </button>
                        <button mat-button (click)="openBottomSheet(currentplay.id)">
                            <mat-icon>share</mat-icon>
                        </button>


                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button color="primary" [routerLink]="'/upload'" mat-button>Accept Challenge</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
<section *ngIf="currentplay">
    <app-vidcard [inparentitem]="currentplay" *ngFor="let item of userChallenges"></app-vidcard>
</section>
<app-footer></app-footer>