<div style="height: 90.2vh;">
    <mat-toolbar>
        <mat-toolbar-row>
            <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>

            <span><a [routerLink]="'/home'" mat-button>    
                <img  src="assets/images/big-logo.png" height="30" /> </a></span>
            <span class="menu-spacer"></span>
            <div fxShow="true" style="min-width: 90%;" fxHide.lt-md fxLayoutAlign="end center">


                <!-- The following menu items will be hidden on both SM and XS screen sizes -->
                <div class="topnav">

                    <div class="search-container">
                        <input type="text" (keyup.enter)="sendMessage(term)" [(ngModel)]="term" #xterm="ngModel" placeholder="Search.." name="search">
                        <button type="submit" (click)="sendMessage(term)"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                    <mat-button-toggle *ngIf="!authid" value="italic" [routerLink]="'/login'">Sign In</mat-button-toggle>
                    <mat-button-toggle value="italic" [routerLink]="'/t/259'" class="bounce ">
                        Prizes
                    </mat-button-toggle>

                </mat-button-toggle-group>

                <mat-button-toggle-group *ngIf="authid" name="fontStyle" aria-label="Font Style">
                    <mat-button-toggle value="bold" [routerLink]="'/upload'">Accept Challenge</mat-button-toggle>

                    <mat-button-toggle><img [src]="image" alt="user profile picture" [matMenuTriggerFor]="menu" class="circle-img"></mat-button-toggle>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item [routerLink]="'/profile'">My profile</button>
                        <button mat-menu-item (click)="logout()">Logout</button>
                    </mat-menu>
                </mat-button-toggle-group>
            </div>
            <mat-button-toggle-group fxShow="true" style="width:100%; text-align:right" fxLayoutAlign="end center" *ngIf="!authid" name="fontStyle" aria-label="Font Style">
                <mat-button-toggle value="italic" [routerLink]="'/login'">Sign In</mat-button-toggle>
                <mat-button-toggle value="italic" [routerLink]="'/t/259'" class="bounce"> Prizes</mat-button-toggle>

            </mat-button-toggle-group>
            <mat-button-toggle-group fxShow="true" style="width:100%; text-align:right" fxLayoutAlign="end center" *ngIf="authid" name="fontStyle" aria-label="Font Style">
                <mat-button-toggle value="italic" class="bounce" [routerLink]="'/t/259'"> Prizes</mat-button-toggle>
                <mat-button-toggle><img [src]="image" alt="user profile picture" [matMenuTriggerFor]="menu" class="circle-img"></mat-button-toggle>

                <mat-menu #menu="matMenu">
                    <button mat-menu-item [routerLink]="'/profile'">My profile</button>
                    <button mat-menu-item (click)="logout()">Logout</button>
                </mat-menu>
            </mat-button-toggle-group>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container fxFlexFill>
        <mat-sidenav #sidenav>
            <mat-nav-list>
                <mat-button-toggle style="min-width:90%;" (click)="sidenav.toggle();" mat-list-item>Close</mat-button-toggle>
                <div class="topnav">
                    <div class="search-container">
                        <input type="text" (keyup.enter)="sendMessage(term)" [(ngModel)]="term" #xterm="ngModel" placeholder="Search.." name="search">
                        <button type="submit" (click)="sendMessage(term); sidenav.toggle();"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
                <mat-button-toggle *ngIf="!authid" style="min-width:100%;" value="italic" [routerLink]="'/login'" (click)="sidenav.toggle();">Sign In</mat-button-toggle>
                <!-- <mat-button-toggle *ngIf="authid" style="min-width:100%;" value="bold" [routerLink]="'/upload'" (click)="sidenav.toggle();">Upload Work</mat-button-toggle> -->
                <mat-button-toggle *ngIf="authid" style="min-width:100%;" value="bold" [routerLink]="'/profile'" (click)="sidenav.toggle();">
                    <img [src]="image" width="130px" alt="user profile picture" class="circle-img"> My Profile</mat-button-toggle>
                <mat-button-toggle *ngIf="authid" style="min-width:100%;" value="bold" (click)="logout(); sidenav.toggle()">Logout</mat-button-toggle>
                <mat-button-toggle value="italic" style="min-width:100%;" [routerLink]="'/about'" (click)="sidenav.toggle();">About us</mat-button-toggle>
                <mat-button-toggle value="italic" style="min-width:100%;" [routerLink]="'/contact'" (click)="sidenav.toggle();">Contact</mat-button-toggle>
                <mat-button-toggle value="italic" style="min-width:100%;" [routerLink]="'/terms'" (click)="sidenav.toggle();">Terms & Conditions</mat-button-toggle>
                <mat-button-toggle value="italic" style="min-width:100%;" [routerLink]="'/privacy'" (click)="sidenav.toggle();">Privacy</mat-button-toggle>
                <mat-button-toggle value="bold" style="min-width:100%;" [routerLink]="'/upload'" (click)="sidenav.toggle();">Accept Challenge</mat-button-toggle>
                <mat-button-toggle class="bounce" value="bold" style="min-width:100%;" [routerLink]="'/t/259'" (click)="sidenav.toggle();">Prizes</mat-button-toggle>

            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content fxFlexFill>
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>