import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
declare let videojs: any;
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent {
  baseurl = "http://api.talentboxx.com//uploads/";
  url = "";
  thumburl = "";
  dataset: any;
  external: any;
  constructor(public service: HttpService, public snackBar: MatSnackBar, private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("data", data);
    this.thumburl = this.baseurl + data.thumb;
    this.url = this.baseurl + data.file;
    this.dataset = data;
    if (data.external) {
      this.external = data.external;
      this.videoPlayer.url = data.external;
      this.videoPlayer.id = this.makeid(15);
      this.videoPlay();
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  onNoClick(): void {
    // this.dialogRef.close();
    this.setFav();
  }
  isloading = false;
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'todos-notification-overlay'
    });
  }
  challenges: any;
  add = 0;
  setFav() {
    if (!localStorage.getItem("authId")) {
      this.showNotification("Please sign in/register to like a video");
      return false;
    }
    if (this.add == 0) { this.add = 1; }
    else { this.add = 0; }
    this.service.getWithAuth("/FileFavs?add=" + this.add + "&id=" + this.dataset.fileid).subscribe(
      (data) => {
        if (data.status == 0) {
          this.isloading = false;
          this.dataset.favs = this.dataset.favs - 1;

        }
        else {
          this.dataset.favs = this.dataset.favs + 1;
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }
  videoPlayer: any = {
    url: '',
    id: '',
    sourceType: 'application/x-mpegURL'
  };

  videoPlay() {
    setTimeout(() => {
      var player = videojs(this.videoPlayer.id);
      player.src(this.videoPlayer.url);
      player.load();
    }, 100);
  }

  makeid(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
