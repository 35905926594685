<app-profile></app-profile>
<div class="propdivbottom">

    <div style="padding-top:50px; text-align:left">
        <h1 class="h1">Your Uploads</h1>
        <!-- start --->
        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top"
            fxLayoutGap="10px">
            <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%"
                *ngFor="let item of userChallenges">
                <mat-card class="exampler-card">
                    <mat-card-header>
                        <mat-card-title>{{item.title}}</mat-card-title>
                        <mat-card-subtitle>{{item.category}}</mat-card-subtitle>
                    </mat-card-header>
                    <!-- <img *ngIf="!item.external" src="{{geturl(item)}}" width="100%" height="180" />-->
                    <!-- <iframe *ngIf="item.external" class="iframe" width="100%" height="180"
                        [src]="sanitize(item.external)" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe> -->
                        <img src="../../assets/play.png" class="setBGPlayIcon" />
                    <img [src]="item?.thumb" *ngIf="item?.thumb" class="placeHolderImage" (error)="errorHandler($event)"
                        [routerLink]="'/t/'+item.id" />
                    <img src="../../assets/images/video-placeholder.png" *ngIf="!item?.thumb" class="placeHolderImage"
                        [routerLink]="'/t/'+item.id" />

                    <mat-card-content>
                        <p>
                            {{item.desc}}
                        </p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button *ngIf="!item.external" disabled mat-button>Waiting for approval </button>
                        <button [routerLink]="'/t/'+item.id" *ngIf="item.external" mat-button>Play</button>
                        <button (click)="deleteVideo(item.id)" mat-button>Delete</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
        <!-- end -->
    </div>




</div>

<app-footer></app-footer>