import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from '../../shared/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Globals } from '../../shared/global';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchTermService } from 'src/app/brodcast/searchterm';
import { BottomSheetOverviewExampleSheet } from '../profile/profile.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-myuploads',
  templateUrl: './myuploads.component.html',
  styleUrls: ['./myuploads.component.css']
})
export class MyuploadsComponent implements OnInit {

  constructor(private searchTermService: SearchTermService, public service: HttpService, private Globals: Globals,
    public snackBar: MatSnackBar, private router: Router, private sanitizer: DomSanitizer, private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute, public dialog: MatDialog) { }
  isloading = false;
  ngOnInit() {
    this.gettMyFiles();
  }
  userChallenges: any;
  edit = false;
  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'todos-notification-overlay'
    });
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  challenges: any;
  setView(id) {

    this.service.getWithAuth("/FileViews?add=1&id=" + id.fileid).subscribe(
      (data) => {
        if (data.status == 0) {
          this.isloading = false;
          id.views = id.views + 1;
          console.log("file", id);
          this.challenges = data.message;
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
      }
    )
  }

  openDialog(x): void {
    this.setView(x);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '650px',
      data: x
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }
  geturl(x) {
    return "http://api.talentboxx.com//uploads/" + x.thumb;
  }

  gettMyFiles() {
    this.isloading = true;
    this.service.getWithAuth("/GetUserChallenges").subscribe(
      (data) => {
        console.log("login data", data);
        console.log("login data", data.error);
        if (data.status == 0) {
          this.isloading = false;
          this.userChallenges = data.message;
          // this.showNotification(data.message);
          this.router.navigate(['/profile']);
        }
        else {
          this.isloading = false;

          this.showNotification(data.message);
        }
        console.log(data);
      },
      (error) => {
        console.log(error);
        this.isloading = false;
        this.showNotification(error.error.error_description);
      }
    )
  };

  deleteVideo(x): void {

    const _bottomSheetRef = this._bottomSheet.open(BottomSheetOverviewExampleSheet, {
      data: { accept: false, text: "" }
    });

    _bottomSheetRef.afterDismissed().subscribe((result) => {
      if(result.accept==false)
      {return;}
      console.log('The dialog was closed', result);
      //this.animal = result;
      let api = "deleteChallenges";

      this.service.getWithAuth("/" + api + "?id=" + x).subscribe((data) => {
        if (data.status == 0) {
          this.showNotification("Video removed");
          this.gettMyFiles();
        } else {
          this.showNotification(data.message);
        }


      });
    }
    );

  }

  errorHandler(event: any) {
    event.target.src = "../../assets/images/video-placeholder.png";
  }
}
