<div class="login">
    <app-nav></app-nav>
    <div style="padding: 10px;" class="vertical-center">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top" fxLayoutGap="10px">
            <div fxFlex="0 1 calc(66.6% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
                <img style="padding:10px;" width="50%" [routerLink]="'/home'" src="assets/images/logo-tag.png">
                <div>
                    <a href="https://play.google.com/store/apps/details?id=com.innoventixsolutions.talentbox">
                    <img class="aligncenter wp-image-1676 size-full non-retina" src="assets/images/app_btn_andoird.png" alt="" width="185" height="38">
                    </a>
                    <a href="https://apps.apple.com/us/app/talentboxx/id1541454800">
                    <img class="aligncenter wp-image-1674 size-full non-retina" src="assets/images/app_btn_apple.png" alt="" width="185" height="38" style="margin-left: 5px;">
                    </a>
                </div>
            </div>
            <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%">
                <form class="register-form" style="padding: 10px;" name="register-form" #registerForm="ngForm" (ngSubmit)="postUser(registerForm)">
                    <div class="form-group">
                        <input type="text" [ngClass]="{error: fullname.errors && submitted}" class="form-control" id="fullname" required name="fullname" [(ngModel)]="user.fullname" #fullname="ngModel" placeholder="Full Name">
                        <mat-form-field>
                            <mat-label>DOB</mat-label>
                            <input name="dob" (click)="picker.open()" class="form-control" required [ngClass]="{error: dob.errors && submitted}" matInput [(ngModel)]="user.dob" #dob="ngModel" [matDatepicker]="picker">
                            <mat-datepicker #picker disabled="false" [startAt]="startDate"></mat-datepicker>
                        </mat-form-field>
                        <div style="padding: 10px;">
                            <label>Sleect Gender</label>
                            <label class="selectionbox">Female
                                <input type="radio" [value]="1" [(ngModel)]="user.gender" #gender="ngModel"   checked="checked" name="gender">
                                <span class="radiomark"></span>
                            </label>
                            <label class="selectionbox">Male
                                <input type="radio" [value]="2" [(ngModel)]="user.gender" #gender="ngModel"  name="gender">
                                <span class="radiomark"></span>
                            </label>
                        </div>

                        <input type="email" [ngClass]="{error: email.errors && submitted}" required name="email" [(ngModel)]="user.email" #email="ngModel" class="form-control" id="email" placeholder="Email">
                    </div>
                    <div class="form-group">
                        <input type="tel" pattern="[0-9]{10}" [ngClass]="{error: mobile.errors && submitted}" required minlength="10" name="mobile" [(ngModel)]="user.mobile" #mobile="ngModel" class="form-control" id="mob" placeholder="Mobile (+91 9999999999)">
                    </div>
                    <div class="form-group">
                        <input type="text" [ngClass]="{error: uid.errors && submitted}" required name="uid" class="form-control" [(ngModel)]="user.uid" #uid="ngModel" id="email" placeholder="User id" autocomplete="off">
                    </div>
                    <div class="form-group">
                        <input type="password" [ngClass]="{error: password.errors && submitted}" required name="password" [(ngModel)]="user.password" #password="ngModel" class="form-control" id="pwd" placeholder="Password" autocomplete="off">
                    </div>
                    <div class="form-group">


                        <mat-accordion>

                            <mat-expansion-panel style="width: 96%;" [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <mat-checkbox required name="terms" [(ngModel)]="user.terms" #terms="ngModel">
                                            <span color="primary">Accept Terms</span> </mat-checkbox>
                                    </mat-panel-title>
                                    <mat-panel-description>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div>
                                    <p style="max-height: 150px; background: #fff; color:#333333; overflow-y: scroll; margin-bottom: 20px;">
                                        <app-terms></app-terms>
                                    </p>


                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>


                    </div>
                    <div class="text-center">
                        <img *ngIf="isloading" src="assets/images/talent-box-loading.gif" width="60px" class="text-center">
                        <button *ngIf="!isloading" type="submit" class="btn btn1">Register</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<div class="footer1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center padding-top5">
                <span class="footer-text">All rights reserved TalentBoxx 2020</span>
            </div>
            <div>
                <span class="footer-text footer-menu" [routerLink]="'/about'">About Us</span>
                <span class="footer-text footer-menu" [routerLink]="'/contact'">Contact Us</span>
                <span class="footer-text footer-menu" [routerLink]="'/terms'">Terms and Condition</span>
                <span class="footer-text footer-menu" [routerLink]="'/privacy'">Privacy and Legal</span>

            </div>
        </div>

    </div>
</div>