<app-loader *ngIf="isloading"></app-loader>
<div class="mainbg">
    <app-nav type="full"></app-nav>
    <div class="col-sm-12 text-center">
        <div class="propdiv text-center" *ngIf="userChallenges">
            <div style="padding-top:0px; text-align:left">
                <h1 class="h1">Current Challenges</h1>
                <!-- start --->
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top"
                    fxLayoutGap="10px" *ngIf="userChallenges.length>0">
                    <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%"
                        *ngFor="let item of userChallenges">
                        <mat-card class="example-card" [routerLink]="'/t/'+item.id">
                            <mat-card-header>
                                <mat-card-title>{{item.title}}</mat-card-title>
                                <mat-card-subtitle>{{item.category}}</mat-card-subtitle>
                            </mat-card-header>

                            <!-- <iframe *ngIf="item?.external?.includes('.m3u8') == false" class="iframe" width="100%"
                                height="220" [src]="item?.safeexternal" frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe> -->

                            <img src="../../assets/play.png" class="setBGPlayIcon" />
                            <img [src]="item?.thumb" *ngIf="item?.thumb" class="placeHolderImage"
                                (error)="errorHandler($event)" />
                            <img src="../../assets/images/video-placeholder.png" *ngIf="!item?.thumb"
                                class="placeHolderImage" />
                            <mat-card-content>
                                <p>
                                    {{item.desc}}
                                </p>
                            </mat-card-content>
                            <mat-card-actions>
                                <button mat-button>Total Submissions {{item.subs}}</button>
                                <button color="primary" [routerLink]="'/upload'" mat-button>Accept Challenge</button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </div>
                <!-- end -->
            </div>
        </div>
    </div>


</div>
<div *ngIf="trendChallenges" class="propdivbottom">

    <h1 class="h1">Trending Now on Talentboxx</h1>
    <label *ngIf="!trendChallenges">No Latest Challenges</label>
    <!-- [ngStyle]="{'background-image': 'url('+geturl(item)+')',  height: '150px'}" -->
    <!-- start --->
    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top" fxLayoutGap="10px"
        *ngIf="trendChallenges?.length>0">
        <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%"
            *ngFor="let item of trendChallenges">

            <mat-card class="example-card" [routerLink]="'/t/'+item.id">
                <mat-card-header>
                    <mat-card-title>{{item.title}}</mat-card-title>
                    <mat-card-subtitle>{{item.category}}</mat-card-subtitle>
                </mat-card-header>
                <!-- <iframe *ngIf="item?.external.includes('.m3u8') == false" class="iframe" width="100%" height="200"
                    [src]="item.safeexternal" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->

                <img src="../../assets/play.png" class="setBGPlayIcon" />
                <img [src]="item?.thumb" *ngIf="item?.thumb" class="placeHolderImage" (error)="errorHandler($event)" />
                <img src="../../assets/video-placeholder.png" *ngIf="!item?.thumb" class="placeHolderImage" />

                <mat-card-content [routerLink]="'/t/'+item.id">
                    <p>
                        {{item.desc}}
                    </p>
                </mat-card-content>
                <mat-card-actions [routerLink]="'/t/'+item.id">
                    <button mat-button>
                        <mat-icon>remove_red_eye</mat-icon>
                        {{item.views}}
                    </button>
                    <button disabled mat-button>
                        <mat-icon>thumb_up_alt</mat-icon>
                        {{item.favs}}
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
    <div style="text-align: center; padding: 10px;">
        <img *ngIf="isloading" src="assets/images/talent-box-loading.gif" width="60px" class="text-center">
        <button *ngIf="!isloading" mat-raised-button color="accent" (click)="getnew()">View More</button>
    </div>
    <!-- end -->
</div>
<section *ngIf="userChallenges">
    <app-vidcard [inparentitem]="item" *ngFor="let item of userChallenges"></app-vidcard>
</section>
<div *ngIf="pastChallenges" class="propdivbottom">

    <h1 class="h1">Past Challenges</h1>
    <label *ngIf="!pastChallenges">No Past Challenges</label>
    <!-- [ngStyle]="{'background-image': 'url('+geturl(item)+')',  height: '150px'}" -->
    <!-- start --->
    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="border-top" fxLayoutGap="10px"
        *ngIf="pastChallenges.length>0">
        <div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%"
            *ngFor="let item of pastChallenges">
            <mat-card class="example-card" [routerLink]="'/ct/'+item.id">
                <mat-card-header>
                    <mat-card-title>{{item.title}}</mat-card-title>
                    <mat-card-subtitle>{{item.category}}</mat-card-subtitle>
                </mat-card-header>
                <!-- <iframe *ngIf="item?.external.includes('.m3u8') == false" class="iframe" width="100%" height="200"
                    [src]="item.safeexternal" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
                <img src="../../assets/play.png" class="setBGPlayIcon" />
                <img [src]="item?.thumb" *ngIf="item?.thumb" class="placeHolderImage" (error)="errorHandler($event)" />
                <img src="../../assets/video-placeholder.png" *ngIf="!item?.thumb" class="placeHolderImage" />

                <mat-card-content>
                    <p>
                        {{item.desc}}
                    </p>
                </mat-card-content>
                <mat-card-actions [routerLink]="'/ct/'+item.id">
                    <button mat-button [routerLink]="'/ct/'+item.id">View Submissions {{item.subs}}</button>
                    <button disabled title="You can not post submissions now" [routerLink]="'/upload'"
                        mat-button>Challenge Ended</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
    <!-- end -->
</div>


<app-footer></app-footer>