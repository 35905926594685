import {NgModule} from '@angular/core';
import {FlexLayoutModule} from "@angular/flex-layout";

import {StyleUtils,StylesheetMap,MediaMarshaller
  ,ɵMatchMedia,BreakPointRegistry,PrintHook,LayoutStyleBuilder,FlexStyleBuilder,ShowHideStyleBuilder
  ,FlexOrderStyleBuilder,FlexFillStyleBuilder,LayoutGapStyleBuilder} from "@angular/flex-layout";

@NgModule({
exports: [
FlexLayoutModule
],
providers: [
StyleUtils,StylesheetMap,MediaMarshaller,
LayoutGapStyleBuilder,FlexFillStyleBuilder,ɵMatchMedia,BreakPointRegistry,PrintHook,LayoutStyleBuilder,FlexStyleBuilder,ShowHideStyleBuilder,FlexOrderStyleBuilder
]
})
export class FlexModule {}