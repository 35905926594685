import { Component, OnInit } from '@angular/core';
import { SearchTermService } from '../../brodcast/searchterm';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-searchnav',
  templateUrl: './searchnav.component.html',
  styleUrls: ['./searchnav.component.css']
})
export class SearchnavComponent implements OnInit {
  subscription: Subscription;
  term:any;
  constructor(private searchTermService:SearchTermService,private router:Router) { 

      // subscribe to home component messages
      this.subscription = this.searchTermService.getMessage().subscribe(message => {
        console.log(`from searchnav`,message);
        if (message) {
          this.image=message;
        } else {
          // clear messages when empty message received
          this.image=localStorage.getItem("userImagePath");
        }
      });
  }
  image="";
  authid=localStorage.getItem("authId");

  ngOnInit() {
    if(this.authid)
    {
      this.image=localStorage.getItem("userImagePath");
    }
    if(!this.authid)
    {localStorage.setItem("userImagePath","assets/images/user.jpg");
    this.image=localStorage.getItem("userImagePath");
    }
  }

  sendMessage(x): void {
    // send message to subscribers via observable subject
   
    let currentUrl = this.router.url;
    if(currentUrl!="/search")
    {
    this.router.navigateByUrl('/search');
    console.log("currentUrl=============",currentUrl);
    setTimeout(() => {
      this.searchTermService.sendSearchMessage(x);
  }, 100);
    }

}

clearMessages(): void {
    // clear messages
    this.searchTermService.clearSearchMessages();
}
logout(){
  localStorage.removeItem("authId");
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("userImagePath");
  this.authid=null;
  this.router.navigateByUrl('/home');
}
}
